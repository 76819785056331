export const api = {
  apiName: "APIGateway",
  provider: "/providers",
  providerlist: "/providers/providerlist",
  addprovider: "/providers/addprovideruser",
  addpatient: "/providers/addpatient",
  patientlist: "/providers/patientlist",
  patientreport: "/patientsession",
  updatepatient: "/providers/updatepatient",
  exportpatientNotes: "/providers/exportpatientnotes"
};
