import React, { useEffect } from "react";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Auth, Hub } from "aws-amplify";
import logo from "./assets/images/logo.png";

const Login = ({ history }) => {
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        history.push("/");
      })
      .catch((err) => {
        console.error(err);
      });
  }, [history]);

  useEffect(() => {
    Hub.listen("auth", (data) => {
      if (data.payload.event === "signIn") {
        history.push("/");
      }
    });
  }, [history]);

  return (
    <div class="login_background">
      <img src={logo} className="Logo" alt="logo" />
      <div>
        <AmplifyAuthenticator style={{ "--container-height": "auto" }}>
          <AmplifySignIn
            headerText="Pulmanage Web Portal Login"
            hideSignUp
            slot="sign-in"
          ></AmplifySignIn>
        </AmplifyAuthenticator>
      </div>
      <div class="contactuslabel">
        <p>Contact Us for Support:</p>
        <p>Support@pulmanage.app</p>
      </div>
    </div>
  );
};

export default Login;
