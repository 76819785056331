import React from "react";
import "../App.css";
import Dashboard from "./Dashboard";

const HomeScreen = () => {
  return (
    <div className="App">
      <Dashboard />
    </div>
  );
};

export default HomeScreen;
