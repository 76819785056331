import React from "react";
import { Divider } from "antd";
import headerLogo from "../assets/images/logo2.png";
import "antd/dist/antd.css";
import "./printout.css";
import "semantic-ui-css/semantic.min.css";

let Organization_name = ""
const PrintHeader = () => {
  const organizationList = localStorage.getItem("organizationList")
    ? JSON.parse(localStorage.getItem("organizationList"))
    : [];
  Organization_name = organizationList ? organizationList[0].name : "";
  return (
    <>
      <div className="pheader">
        <div className="ptop-menu-left">
          <img src={headerLogo} className="pheaderLogo" />
        </div>
        <div className="ptop-menu-text">
          <div className="ptop-menu-right">
            <h3 style={{ margin: 0 }} className="pnavUser">
              <b>Organization: </b>{" "}
              {Organization_name}{" "}
            </h3>
            <Divider className="pvertical-divider" type="vertical" />
            <h3 style={{ margin: 0 }} className="pnavUser">
              <b>Provider: </b>{" "}
              {localStorage.getItem("providerName")
                ? localStorage.getItem("providerName")
                : ""}{" "}
            </h3>
          </div>
          <h3 style={{ margin: 0 }} className="pnavReport">
            <b>Remote Monitoring Report</b>
          </h3>
        </div>
      </div>
    </>
  );
};

export default PrintHeader;
