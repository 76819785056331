import React from "react";

import "antd/dist/antd.css";
import "../App.css";
import "semantic-ui-css/semantic.min.css";

const Footer = () => {
    return (
        <>
            <div
                style={{ padding: 0, backgroundColor: '#4a4a4a' }}
                class="ui inverted vertical footer segment form-page"
            >
                <div class="ui container">
                    <div style={window.innerWidth>1000?{fontSize:'0.86vw'}:{fontSize:'1.6vw'}} class="ui secondary  menu">
                        <a id="footerElement" class="item">
                            Privacy Policy
                        </a>
                        <a id="footerElement" class="item">
                            Contact Us
                        </a>
                        <a id="footerElement" class="item">
                            Copyright © 2021 Pulmanage Inc.
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Footer;