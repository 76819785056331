import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import {
  Button,
  Card,
  Table,
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  Divider,
} from "antd";
import { SyncOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { patientsColumn } from "./column";
import { api } from "../../services/link";
import "../../App.css";

const { Option } = Select;

const PatientsList = () => {
  const [form] = Form.useForm();
  let history = useHistory();
  const [showAddPatient, setShowAddPatient] = useState(false);
  const [refSpin, setRefSpin] = useState(false);
  const [newPatientData, setNewPatientData] = useState({});
  const [patientTableData, setPatientTableData] = useState([]);
  const [userId, setUserId] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const fontSizeFactor = window.innerWidth>1000?'0.9vw':'1.65vw';

  const role = localStorage.getItem("currentOrgRole");

  useEffect(() => {
    setRefSpin(true);
    Auth.currentSession()
      .then((user) => {
        setJwtToken(user.idToken.jwtToken);
        setUserId(user.accessToken.payload.username);

        const myInit = {
          headers: {
            Authorization: `Bearer ${user.idToken.jwtToken}`,
          },
          body: {
            username: user.accessToken.payload.username,
            orgName: localStorage.getItem("currentOrgID"),
            type: "assigned", //assigned or all can be used
          },
        };
        API.post(api.apiName, api.patientlist, myInit)
          .then((response) => {
            if (response.type === "success") {
              setPatientTableData(response.data);
              setRefSpin(false);
            }
            setRefSpin(false);
          })
          .catch((error) => {
            errorMsg(error);
          });
      })
      .catch(() => {
        history.push("/login");
      });
  }, [history]);

  const fetchData = (type) => {
    setRefSpin(true);
    setPatientTableData([]);
    const myInit = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: {
        username: userId,
        orgName: localStorage.getItem("currentOrgID"),
        type, //: "assigned", assigned or 'all' can be used
      },
    };
    API.post(api.apiName, api.patientlist, myInit)
      .then((response) => {
        if (response.type === "success") {
          setPatientTableData(response.data);
        }
        setRefSpin(false);
      })
      .catch((error) => {
        errorMsg(error);
      });
  };

  const handleChange = (event) => {
    let oldData = newPatientData;
    oldData[event.target.name] = event.target.value;
    setNewPatientData(oldData);
  };
  const handleChangeSex = (event) => {
    let oldData = newPatientData;
    oldData["sex"] = event;
    setNewPatientData(oldData);
  };
  const handleChangeRace = (event) => {
    let oldData = newPatientData;
    oldData["race"] = event;
    setNewPatientData(oldData);
  };

  const onChangeDate = (date, val) => {
    let oldData = newPatientData;
    oldData["age"] = val;
    setNewPatientData(oldData);
  };

  //Cancel add a new patient
  const closeAddUser = () => {
    setShowAddPatient(false);
    form.resetFields();
  };

  //  integrate the api here to add a new patient
  const onSubmit = () => {
    const myInit = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: {
        providerid: userId,
        orgName: localStorage.getItem("currentOrgID"),
        PatientData: newPatientData,
      },
    };
    API.post(api.apiName, api.addpatient, myInit)
      .then((response) => {
        console.log(response);
        if (response.type === "success") {
          Modal.success({
            title: "Success!",
            content: response.data,
            onOk() {
              form.resetFields();
              fetchData("assigned");
              setShowAddPatient(false);
            },
          });
        } else {
          errorMsg(response.data);
        }
      })
      .catch((error) => {
        errorMsg(error);
      });
  };

  // for general error msg
  const errorMsg = (message) => {
    Modal.error({
      title: "Error!",
      content: message,
    });
  };

  const getUser = (record) => {
    history.push({
      pathname: "/patient-detail",
      state: record,
    });
  };

  const onTypeSelected = (val) => {
    fetchData(val);
  };

  return (
    <div className="TableContainer">
      <Card>
        {role === "Org_Admin" ? (
          <div className="buttonContainer">
            <div>
              <Input.Group compact>
                <Input className="input-select" value="Patients" disabled />
                <Select
                  defaultValue="assigned"
                  className="select-after"
                  onChange={onTypeSelected}
                >
                  <Option value="assigned">Assigned</Option>
                  <Option value="all">All</Option>
                </Select>
              </Input.Group>
            </div>

            <div>
              <Button
                onClick={() => {
                  fetchData("assigned");
                }}
              >
                <SyncOutlined spin={refSpin} />
                Refresh
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setShowAddPatient(true);
                  form.resetFields();
                }}
              >
                Add Patient
              </Button>
            </div>
          </div>
        ) : (
          <div className="buttonContainer">
            <Button
              onClick={() => {
                setShowAddPatient(true);
                form.resetFields();
              }}
            >
              Add Patient
            </Button>

            <Button
              onClick={() => {
                fetchData("assigned");
              }}
            >
              <SyncOutlined spin={refSpin} />
              refresh
            </Button>
          </div>
        )}

        <Table
          dataSource={patientTableData}
          className= {window.innerWidth>1000?"table-patientlist":"table-patientlist2"} 
          columns={patientsColumn}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                getUser(record);
              }, // click row
            };
          }}
        />
      </Card>

      {/* for Modal */}
      <Modal
        title="New Patient Information"
        visible={showAddPatient}
        onOk={closeAddUser}
        onCancel={closeAddUser}
        width={'70%'}
        style={{ top: 20, maxWidth:720 }}
        footer={[
          <Button type="primary" key="submit" onClick={onSubmit} size={"large"} style={{ width: "30%", background: "rgb(68,192,192)", borderColor: "white" }}>
            Confirm
          </Button>,
        ]}
      >
        <Form layout={"vertical"} form={form}>
          <div className="row">
            <Form.Item label="MRN*" className="form-input-mrn" name="mrn" style={{ marginRight: "3%", marginBottom: "2%" }}>
              <Input
                placeholder="Enter Patient's MRN"
                name="mrn"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item label="First Name*" className="form-input-fname" name="fname" style={{ marginRight: "3%", marginBottom: "2%" }}>
              <Input
                placeholder="Enter Patient's First Name"
                name="fname"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
            <Form.Item label="Last Name*" className="form-input-lname" name="lname" style={{ marginRight: "3%", marginBottom: "2%" }}>
              <Input
                placeholder="Enter Patient's Last Name"
                name="lname"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
          </div>

          <div className="row">
            <Form.Item
              label="Email Address*"
              className="form-input-fname"
              name="email"
              style={{ marginRight: "3%", marginBottom: "2%" }}
            >
              <Input
                placeholder="Enter Patient's Email Address"
                name="email"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
            <Form.Item label="Phone Number*" className="form-input-lname" name="phone" style={{ marginRight: "2%", marginBottom: "2%" }}>
              <Input
                placeholder="Enter Patient's Phone Number"
                name="phone"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
          </div>

          <Divider style={{ margin: 3 }} />

          <div className="row">
            <Form.Item label="Date of Birth*" className="form-input" name="age" style={{ marginRight: "2%", marginBottom: "2%" }}>
              <DatePicker name="age" onChange={onChangeDate} />
            </Form.Item>
            <Form.Item label="Sex at Birth*" className="form-input-sex" name="sex" style={{ marginRight: "2%", marginBottom: "2%" }}>
              <Select
                name="sex"
                defaultValue="Please select one"
                onChange={handleChangeSex}
              >
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Race*" className="form-input-race" name="race" style={{ marginRight: "3%", marginBottom: "2%" }}>
              <Select
                className="select-option-text-patientform"
                name="race"
                defaultValue="Please select one"
                onChange={handleChangeRace}
              >
                <Option value="Caucasian">Caucasian</Option>
                <Option value="African American">African American</Option>
                <Option value="North East Asian">North East Asian</Option>
                <Option value="South East Asian">South East Asian</Option>
                <Option value="Other/mixed">Other/mixed</Option>
              </Select>
            </Form.Item>
          </div>

          <div className="row">
            <Form.Item label="Height(in)*" className="form-input-fname" name="height" style={{ marginRight: "2%", marginBottom: "2%" }}>
              <Input
                placeholder="Enter Patient's Height in inches."
                name="height"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
            <Form.Item label="Weight(lbs)*" className="form-input-lname" name="weight" style={{ marginBottom: "2%" }}>
              <Input
                placeholder="Enter Patient's Weight in pounds."
                name="weight"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
          </div>

          <Divider style={{ margin: 3 }} />

          <div className="row">
            <Form.Item
              label="Device Serial Number"
              className="form-input"
              name="serialno"
              style={{ marginRight: "1%", marginBottom: "2%" }}
            >
              <Input
                placeholder="Enter Vitalograph device serial number."
                name="serialno"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
            <Form.Item
              label="Type of Lung Condition"
              className="form-input-sex"
              name="lungcondition"
              style={{ marginRight: "1%", marginBottom: "2%" }}
            >
              <Input
                placeholder="Select one of following"
                name="lungcondition"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
            <Form.Item
              label="Severity of Condition"
              className="form-input-sex"
              name="severity"
              style={{ marginRight: "3%", marginBottom: "2%" }}
            >
              <Input
                placeholder="Select one of following"
                name="severity"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
          </div>

          <div className="row">
            <Form.Item
              label="Medications"
              className="form-input-fname"
              name="medications"
              style={{ marginRight: "3%" }}
            >
              <Input
                placeholder="Enter the medications list patient consume"
                name="medications"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
            <Form.Item
              label="Additional Airway Therapies"
              className="form-input-lname"
              name="therapies"
            >
              <Input
                placeholder="Enter Patient's Airway therapies"
                name="therapies"
                onChange={handleChange}
                autoComplete="off" style={{  fontSize: fontSizeFactor }}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default PatientsList;
