export const providerColumn = [
  {
    title: "First Name",
    dataIndex: "given_name",
    key: "given_name",
  },
  {
    title: "Last Name",
    dataIndex: "family_name",
    key: "family_name",
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Created Date",
    dataIndex: "UserCreateDate",
    key: "UserCreateDate",
  },
  {
    title: "Last Modified Date",
    dataIndex: "UserLastModifiedDate",
    key: "UserLastModifiedDate",
  },
  {
    title: "Status",
    dataIndex: "UserStatus",
    key: "UserStatus",
  },
  {
    title: "Enabled",
    dataIndex: "Enabled",
    key: "Enabled",
  },
];
