import React from "react";
import Login from "./custom_login";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeScreen from "./pages/HomeScreen";
import Providers from "./pages/providers/Providers";
import Patients from "./pages/patients/Patients";
import PatientDetail from "./pages/patients/patient-details";

function App() {
  //load home page if user has valid token or load login page
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={HomeScreen} />
        <Route path="/login" exact component={Login} />
        <Route path="/providers" exact component={Providers} />
        <Route path="/patients" exact component={Patients} />
        <Route path="/patient-detail" exact component={PatientDetail} />
        <Route component={Login} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;