import React from "react";
import Headers from "../../components/Headers";
import Footer from "../../components/Footer";
import "../../App.css";
import PatientsList from "./patients-list";

const Patients = () => {
  return (
    <>
      <div className="App">
        <Headers />
        <PatientsList />
      </div>
      <Footer />
    </>
  );
};

export default Patients;
