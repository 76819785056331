import React from "react";
import "./printout.css";
import PrintHeader from "./printHeader";
import { Row, Col, Button, Card, Table, Image } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import moment from "moment";
import { CartesianGrid, ComposedChart, BarChart, XAxis, YAxis, Bar, ResponsiveContainer } from "recharts";

class PrintV1 extends React.Component {
  //handle table for lung function 
  plungFunctionChart() {
    return (
      <>
        <div className="prow-container1" >
          <span className="row-containerleft">Device Data</span>
          <span className="row-containerright">
            Lung Function Test
          </span>
        </div>
        <div className="row-container">
          <Table
            bordered={false}
            className="ptable-graph"
            dataSource={this.lungFunction(this.props.sessionIds, this.props.userSessionData)}
            columns={this.props.lungFunctionColumn}
            pagination={false}
          />
        </div>
        <br />
        <div style={{ fontSize: "0.4em", textAlign: "left",marginBottom:'3px' }}>*FEV 10 is the surrogate for FVC in this chart</div>
      </>
    );
  }

  //buttons for lung function
  buttons = () => {
    return (
      <div className="pcol-button">
        <Button
          size={"small"}
          ghost={true}
          className={
            this.props.buttonVisiblity.pefvisible.state
              ? "ppef-button-selected"
              : "pbutton-unselected"
          }
        >
          <span className="pbuttonText">PEF</span>
        </Button>
        <Button
          size={"small"}
          ghost={true}
          className={
            this.props.buttonVisiblity.fev10visible.state
              ? "ppef-button-selected"
              : "pbutton-unselected"
          }
        >
          <span className="pbuttonText">FVC(L)</span>
        </Button>
        <Button
          size={"small"}
          ghost={true}
          className={
            this.props.buttonVisiblity.fev1visible.state
              ? "pfev1-button-selected"
              : "pbutton-unselected"
          }
        >
          <span className="pbuttonText">FEV 1(L)</span>
        </Button>
        <Button
          size={"small"}
          ghost={true}
          className={
            this.props.buttonVisiblity.fev75visible.state
              ? "pfev75-button-selected"
              : "pbutton-unselected"
          }
        >
          <span className="pbuttonText">FEV 0.75(L)</span>
        </Button>
        <Button
          size={"small"}
          ghost={true}
          className={
            this.props.buttonVisiblity.fev2575visible.state
              ? "ppef-button-selected"
              : "pbutton-unselected"
          }
        >
          <span className="pbuttonText">FEV 25/75 (L/sec)</span>
        </Button>
        <Button
          size={"small"}
          ghost={true}
          className={
            this.props.buttonVisiblity.fev1fev10visible.state
              ? "ppef-button-selected"
              : "pbutton-unselected"
          }
        >
          <span className="pbuttonText">FEV1/FVC</span>
        </Button>
      </div>
    );
  };

  //handle individual chart in lung function table
  lungFunction = (session, data) => {
    var data3 = { 0: this.buttons() };
    var data_info = {};
    session.forEach((g) => {
      var cur = moment(data[g][0].Added_time).format("MMDDYYYY");
      var value = {};
      value["label"] = g;
      value["PEF"] = Math.max(
        parseInt(data[g][0].PEF),
        parseInt(data[g][1].PEF),
        parseInt(data[g][2].PEF)
      );
      value["FEV1"] = Math.max(
        parseInt(data[g][0].FEV1),
        parseInt(data[g][1].FEV1),
        parseInt(data[g][2].FEV1)
      );
      value["FEV10"] = Math.max(
        parseInt(data[g][0].FEV10),
        parseInt(data[g][1].FEV10),
        parseInt(data[g][2].FEV10)
      );
      value["FEV75"] = Math.max(
        parseInt(data[g][0].FEV75),
        parseInt(data[g][1].FEV75),
        parseInt(data[g][2].FEV75)
      );
      value["FEF2575"] = Math.max(
        parseInt(data[g][0].FEF2575),
        parseInt(data[g][1].FEF2575),
        parseInt(data[g][2].FEF2575)
      );
      value["FEV1_FEV10"] = Math.max(
        parseInt(data[g][0].FEV1_FEV10),
        parseInt(data[g][1].FEV1_FEV10),
        parseInt(data[g][2].FEV1_FEV10)
      );
      data3[cur] = data3[cur] ? data3[cur].concat([value]) : [value];
      data_info[cur] = data_info[cur] ? data_info[cur].concat([value]) : [value];
    });
    var count = 0;
    Object.keys(this.props.buttonVisiblity).forEach(b => {
      if (this.props.buttonVisiblity[b].state) {
        count += 1
      }
    })

    for (var h in data3) {
      if (h !== "0") {
        if (data3[h].length > 0) {
          data3[h] = (
            <ResponsiveContainer width={'100%'} height={130}>
              <BarChart
                barGap={0}
                barSize={count > 1 ? 2 : 4}
                height={130}
                data={data3[h].reverse().slice(0, 4).reverse()}
              >
                <XAxis dataKey="label" tick={false} hide={true} />
                <YAxis hide={true} domain={[0, 5]} hide={true} />
                {this.props.buttonVisiblity.pefvisible.state ? <Bar dataKey={"PEF"} fill="rgb(3,117,128)" /> : ''}
                {this.props.buttonVisiblity.fev10visible.state ? <Bar dataKey={"FEV10"} fill="rgb(3,117,128) " /> : ''}
                {this.props.buttonVisiblity.fev1visible.state ? <Bar dataKey={"FEV1"} fill="rgb(68,192,192)" /> : ''}
                {this.props.buttonVisiblity.fev75visible.state ? <Bar dataKey={"FEV75"} fill="rgb(20,65,86)" /> : ''}
                {this.props.buttonVisiblity.fev2575visible.state ? <Bar dataKey={"FEF2575"} fill="rgb(3,117,128)" /> : ''}
                {this.props.buttonVisiblity.fev1fev10visible.state ? <Bar dataKey={"FEV1_FEV10"} fill="rgb(3,117,128)" /> : ''}
              </BarChart>
            </ResponsiveContainer>
          );
          var dataSel = { 'PEF': 0, 'FEV1': 0, 'FEV10': 0 }
          data_info[h].forEach(lung => {
            if (parseFloat(lung['PEF']) >= dataSel['PEF']) {
              dataSel['PEF'] = parseFloat(lung['PEF'])
            }
            if (parseFloat(lung['FEV1']) >= dataSel['FEV1']) {
              dataSel['FEV1'] = parseFloat(lung['FEV1'])
            }
            if (parseFloat(lung['FEV10']) >= dataSel['FEV10']) {
              dataSel['FEV10'] = parseFloat(lung['FEV10'])
            }
          });
          data_info[h] = (
            <div className="printLungData">
             <span style={{float:'left'}}>PEF:</span><span style={{float:'right'}}>{dataSel['PEF']} L/min</span> <br />
             <span style={{float:'left'}}>FVC:</span><span style={{float:'right'}}>{dataSel['FEV10'] / 100} L</span> <br />
             <span style={{float:'left'}}>FVC %pred:</span><span style={{float:'right'}}>{((dataSel['FEV10'] / 100) / this.props.FVCM * 100).toFixed(1)}%</span> <br />
             <span style={{float:'left'}}>FEV1:</span><span style={{float:'right'}}>{dataSel['FEV1'] / 100} L</span> <br />
             <span style={{float:'left'}}>FEV1 %pred.:</span><span style={{float:'right'}}>{((dataSel['FEV1'] / 100) / this.props.FEV1M * 100).toFixed(1)}%</span> <br />
            </div>
          );
        }
      }
    }
    data3["key"] = '1'
    return [data3, data_info];
  };
  //handle table for respiratory rate and temperature
  respiratoryRate = () => {
    return (
      <>
        <div
          className="prow-containerR"
        >
          <span className="row-containerleft">Self-Reported</span>
          <span className="row-containerright">
            Temperature{" "}
          </span>
          <span className="row-containerright">
            Respiratory Rate
          </span>
          <span className="row-containerright">
            Temperature &amp; Respiratory Rate
          </span>
        </div>
        <div className="row-container">
          <Table
            bordered={true}
            className="ptable-graph2"
            dataSource={this.getRespiratoryRateData(
              "Respiratory Rate",
              "Temperature"
            )}
            columns={this.props.lungFunctionColumn}
            pagination={false}
          />
        </div>
      </>
    );
  };
  //handle y-axis range for respiratory rate
  textLabel = () => {
    return (
      <div className="pcol-label-text">
        <p>40</p>
        <p>30</p>
        <p>20</p>
        <p>10</p>
      </div>
    );
  };
  //handle individual chart in respiratory rate and temperature table
  getRespiratoryRateData = (params, params2) => {
    var data3 = { 0: this.textLabel() };
    var data_info = {};
    this.props.sessionIds.forEach((g) => {
      var cur = this.props.sessionIdsForDates[g];
      var value = {};
      value["label"] = g;
      value[params] = this.props.respiratoryRateData[g]
        ? this.props.respiratoryRateData[g].respiratoryrate
        : 0;
      value[params2] = this.props.temperatureData[g]
        ? parseFloat(this.props.temperatureData[g].temperature)
        : 0.0;
      data3[cur] = data3[cur] ? data3[cur].concat([value]) : [value];
      data_info[cur] = data_info[cur] ? data_info[cur].concat([value]) : [value];
    });
    for (var h in data3) {
      if (h !== "0") {
        if (data3[h].length > 0) {
          data3[h] = (
            <ResponsiveContainer width={'45%'} height={55}>
              <ComposedChart
                height={55}
                data={data3[h].reverse().slice(0, 4).reverse()}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="label" tick={false} hide={true} />

                <Bar dataKey={params} barSize={3} fill="rgb(171,227,226)" />
                <Bar dataKey={params2} barSize={2} fill="rgb(241,63,78)" />
              </ComposedChart>
            </ResponsiveContainer>
          );
          var dataSel = { 'Temperature': 0, 'Respiratory Rate': 0}
          data_info[h].forEach(lung => {
            if (parseFloat(lung['Temperature']) >= dataSel['Temperature']) {
              dataSel['Temperature'] = parseFloat(lung['Temperature'])
            }
            if (parseFloat(lung['Respiratory Rate']) >= dataSel['Respiratory Rate']) {
              dataSel['Respiratory Rate'] = parseFloat(lung['Respiratory Rate'])
            }
          });
          data_info[h] = (
            <div className="printLungData">
             <span style={{float:'left'}}>Temperature:</span><span style={{float:'right'}}>{dataSel['Temperature']}&#176;F</span> <br />
             <span style={{float:'left'}}>Respiratory Rate:</span><span style={{float:'right'}}>{dataSel['Respiratory Rate']}</span> <br />
            </div>
          );
        }
      }
    }
    data3["key"] = '1'
    return [data3,data_info];
  };
  //handle table for symptom matrix
  symptomMatrix = () => {
    return (
      <>
        <div
          className="prow-container2"
        >
          <span className="row-containerleft" >Self-Reported</span>
          <span className="row-containerright">
            Patient Symptoms
          </span>
        </div>
        <div className="prow-container">
          <Table
            bordered={false}
            rowClassName={(record, index) =>
              this.getBackground(index) ? "table-row-highlight" : ""
            }
            className="ptable-graph3"
            dataSource={this.getSymptomMatrixData()}
            columns={this.props.lungFunctionColumn}
            pagination={false}
          />
        </div>
      </>
    );
  };
  //handle data parsing for image asset for symptom matrix
  getSymptomMatrixData = () => {
    var dataSource6 = [];
    var test = {};
    var dataSource5 = {};
    if (Object.keys(this.props.symptommsData).length > 0) {
      this.props.sessionIds.forEach((j) => {
        var cur = this.props.sessionIdsForDates[j];
        if (this.props.symptommsData[j] && cur) {
          Object.keys(this.props.symptommsData[j]).forEach((k) => {
            if (
              this.props.symptommsData[j][k] === "true" ||
              k === "Rash" ||
              k === "Chills"
            ) {
              if (!test[this.props.symptomMeta[k].loc]) {
                test[this.props.symptomMeta[k].loc] = {
                  [cur]: [this.imageAsset(this.props.symptomMeta[k] ? this.props.symptomMeta[k].asset : "001-Present.png")],
                };
              } else if (!test[this.props.symptomMeta[k].loc][cur]) {
                test[this.props.symptomMeta[k].loc][cur] = [
                  this.imageAsset(this.props.symptomMeta[k] ? this.props.symptomMeta[k].asset : "001-Present.png"),
                ];
              } else {
                test[this.props.symptomMeta[k].loc][cur].push(
                  this.imageAsset(this.props.symptomMeta[k] ? this.props.symptomMeta[k].asset : "001-Present.png")
                );
              }
            } else if (k === "Cough" || k === "Shortness of Breath") {
              if (!test[this.props.symptomMeta[k].loc]) {
                test[this.props.symptomMeta[k].loc] = {
                  [cur]: [this.imageAsset(this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k]] ? this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k]].value : "001-Present.png")],
                };
              } else if (!test[this.props.symptomMeta[k].loc][cur]) {
                test[this.props.symptomMeta[k].loc][cur] = [
                  this.imageAsset(this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k]] ? this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k]].value : "001-Present.png"),
                ];
              } else {
                test[this.props.symptomMeta[k].loc][cur].push(
                  this.imageAsset(this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k]] ? this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k]].value : "001-Present.png")
                );
              }
            } else if (k === "Productive Cough") {
              if (!test[this.props.symptomMeta[k].loc]) {
                test[this.props.symptomMeta[k].loc] = {
                  [cur]: [this.imageAsset(this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k][0]] ? this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k][0]].value : "001-Present.png")],
                };
              } else if (!test[this.props.symptomMeta[k].loc][cur]) {
                test[this.props.symptomMeta[k].loc][cur] = [
                  this.imageAsset(this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k][0]] ? this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k][0]].value : "001-Present.png"),
                ];
              } else {
                test[this.props.symptomMeta[k].loc][cur].push(
                  this.imageAsset(this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k][0]] ? this.props.symptomMeta[k].asset_meta[this.props.symptommsData[j][k][0]].value : "001-Present.png")
                );
              }
            }
          });
        } else if (cur) {
          test[this.props.symptomMeta["No Symptoms"].loc] = {
            [cur]: [this.imageAsset(this.props.symptomMeta["No Symptoms"].asset)],
          };
        }
        else {
          dataSource5[cur] = "No Symptoms";
        }
      });
    }
    dataSource5["0"] = (
      <span className={this.getSymptomLabelStyle(1)}>{this.props.symptommatrix[1 - 1]}</span>
    );
    dataSource6.push(dataSource5);
    var dataSource4 = [];
    for (var m = 1; m <= 18; m++) {
      var dataSource2 = {};
      var data3 = [];
      if (test[m]) {
        Object.keys(test[m]).forEach((j) => {
          data3 = [];
          test[m][j].forEach((l) => {
            data3.push(l);
          });
          dataSource2[j] = data3;
        });
      }
      dataSource2["0"] = (
        <span className={this.getSymptomLabelStyle(m)}>{this.props.symptommatrix[m - 1]}</span>
      );
      dataSource4["key"] = toString(m)
      dataSource4.push(dataSource2);
    }
    return dataSource4;
  };
  //handle image asset for symptom matrix
  imageAsset = (image) => {
    let imageurl = require("../assets/icons/" + image);
    return (
      <Image
        className="piconsClass"
        width="0.7em"
        src={imageurl.default}
        preview={false}
        alt="image not found"
      />
    );
  };
  //handle image asset for symptom matrix legend
  imageAsset2 = (image) => {
    let imageurl = require("../assets/icons/" + image);
    return (
      <Image
        className="piconsClass2"
        width="0.4em"
        src={imageurl.default}
        preview={false}
        alt="image not found"
      />
    );
  };
  //handle highlight of titles in symptom matrix
  getBackground = (index) => {
    if (index === 1 || index === 6 || index === 9 || index === 15) {
      return true;
    } else {
      return false;
    }
  };
  //handle label style symptom matrix
  getSymptomLabelStyle = (index) => {
    if (index === 1) {
      return "pnoSymptom";
    } else if (index === 2 || index === 7 || index === 10 || index === 16) {
      return "psectionLabel";
    } else {
      return "psymptomLabels";
    }
  };

  //handle legend for symptom matrix
  symptomMatrixLegend = () => {
    var dat = [];
    var legendDat = {};
    Object.keys(this.props.symptomMeta["Shortness of Breath"].asset_meta).forEach(asset => {
      dat.push(
        <div className="plegendItems">
          {this.imageAsset2(this.props.symptomMeta["Shortness of Breath"].asset_meta[asset].value)}
          <span className="plegendText"> = </span>
          <span className="plegendText">{this.props.symptomMeta["Shortness of Breath"].asset_meta[asset].label}</span>
        </div>
        );
    })
    legendDat["shortnessofbreath"] = dat
    dat = []
    dat.push(
      <div className="plegendItems">
        {this.imageAsset2("001-Present.png")}
        <span className="plegendText"> = Present</span>
      </div>
      );
    Object.keys(this.props.symptomMeta["Cough"].asset_meta).forEach(asset => {
      dat.push(
      <div className="plegendItems">
        {this.imageAsset2(this.props.symptomMeta["Cough"].asset_meta[asset].value)}
        <span className="plegendText"> = </span>
        <span className="plegendText">{this.props.symptomMeta["Cough"].asset_meta[asset].label}</span>
      </div>
      );
    })
    legendDat["symptoms"] = dat
    dat = []
    Object.keys(this.props.symptomMeta["Productive Cough"].asset_meta).forEach(asset => {
      dat.push(
        <div className="plegendItems">
          {this.imageAsset2(this.props.symptomMeta["Productive Cough"].asset_meta[asset].value)}
          <span className="plegendText"> = </span>
          <span className="plegendText">{this.props.symptomMeta["Productive Cough"].asset_meta[asset].label}</span>
        </div>
        );
    })
    legendDat["sputum"] = dat
    return (
      <div className="legendArea">
        <div className="plegendStack">
          <div className="plegendStackHead">Symptoms</div>
          {legendDat.symptoms}
        </div>
        <div className="plegendStack">
          <div className="plegendStackHead">Shortness of Breath</div>
          {legendDat.shortnessofbreath}
        </div>
        <div className="plegendStack">
          <div className="plegendStackHead">Sputum Color</div>
          {legendDat.sputum}
        </div>
      </div>);
  }
  render() {
    return (
      <div className="pApp">
        <PrintHeader />

        <div className="pTableContainer">
          <Card className="printpage"
            bordered={false}
            style={{ maxWidth:"8.5in",minWidth: "8in",width:"95vw" }}
            title={
              <div className="ptable-header-text">
                <span style={{ color: "gray" }}>
                  Patient Name: {this.props.userName} &nbsp;&nbsp;&nbsp;&nbsp; |
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span style={{ color: "#047181" }}> MRN : {this.props.userMRN}</span>
              </div>
            }
          >

            <Row style={{ marginLeft: '1em', marginRight: '1em' }}>
              {Object.keys(this.props.userData).map((key, index) => {
                return (
                  <Col span={4} style={{ marginBottom: 4 }}>
                    <Row className="row-header prow-item1">
                      <Col lg>
                        <p style={{ fontSize: "0.9em" }}>{key}</p>
                      </Col>
                    </Row>
                    <Row className="prow-item">
                      <Col>
                        <label> {this.props.userData[key]} </label>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
            <>
              <div className="pnotesArea">
                Additional Notes: <br />
                {this.props.notes}<br />
                <span style={{float:'left'}}>FVC LLN:</span><span style={{float:'right'}}>{this.props.FVCLLN.toFixed(4)} L</span> <br />
                <span style={{float:'left'}}>FEV1 LLN:</span><span style={{float:'right'}}>{this.props.FEV1LLN.toFixed(4)} L</span> <br />
              </div>
              <div className="pchartArea">

                <Card className="pchartCard" bordered={false}>
                  <div style={{height:'1.1em'}}>
                    <Button
                      className="chartPagination"
                      type="text"
                      icon={<CaretLeftOutlined style={{ fontSize: "0.6em" }} />}
                    />
                    <span style={{ fontSize: "0.6em" }}>
                      {this.props.reportPagination}
                    </span>
                    <Button
                      className="chartPagination"
                      type="text"
                      icon={<CaretRightOutlined style={{ fontSize: "0.6em" }} />}
                    />
                  </div>
                  <div style={{height:'1em'}}>
                    <span style={{ fontSize: "0.8em" }}>
                      {this.props.sessionCnt + " sessions"}
                    </span>
                  </div>

                  {this.plungFunctionChart()}
                  {this.respiratoryRate()}
                 
                  {this.symptomMatrix()}

                </Card>

              </div>
              <div className="plegendarea">{this.symptomMatrixLegend()}</div>
            </>
          </Card>
        </div>

      </div>

    );
  }
}
export default PrintV1;