export const patientsColumn = {
    0:'000-Nothing-at-all.png',
    1:'001-Clear.png',
    2:'001-Just-Noticeable.png',
    3:'001-Present.png',
    4:'002-Occasional.png',
    5:'002-Very-Slight.png',
    6:'002-White.png',
    7:'003-Persistent.png',
    8:'003-Slight.png',
    9:'003-Tan.png',
    10:'004-Chronic.png',
    11:'004-Moderate.png',
    12:'004-Yellow.png',
    13:'005-Green.png',
    14:'005-Some-Difficulty.png',
    15:'006-Brown.png',
    16:'006-Severe.png',
    17:'007-Bloody.png',
    18:'007-Very-Severe.png',
    19:'008-Almost-Maximal.png',
    20:'008-Blood-Tinged.png',
    21:'009-Maximal.png',
    22:'010-Panic-Level.png',
    23:'Green-Check.png',
};
  