import React, { useState, useEffect } from "react";
import "./calendar.css";
import moment from "moment";
import {
    DoubleLeftOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
    ContactsOutlined,
} from "@ant-design/icons";
import {
    Table,
    Button,
} from "antd";
import { StatusGoodSmall } from 'grommet-icons';


const widthfactor = window.innerWidth >1000 ? '6vw':'2.5vw'

const PCalendar = (props) => {
    var calendarSessionData = {}
    var week = [
        {
            title: "Mon",
            dataIndex: 1,
            key: 1,
            width: '14.29%',
            render(date, record) {
                return {
                    props: {
                        style: { background: getHighlights(date, record) }
                    },
                    children: <div>{date}</div>
                };
            }
        },
        {
            title: "Tue", dataIndex: 2, key: 2, width: '14.29%', render(date, record) {
                return {
                    props: {
                        style: { background: getHighlights(date, record) }
                    },
                    children: <div>{date}</div>
                };
            }
        },
        {
            title: "Wed", dataIndex: 3, key: 3, width: '14.29%', render(date, record) {
                return {
                    props: {
                        style: { background: getHighlights(date, record) }
                    },
                    children: <div>{date}</div>
                };
            }
        },
        {
            title: "Thu", dataIndex: 4, key: 4, width: '14.29%', render(date, record) {
                return {
                    props: {
                        style: { background: getHighlights(date, record) }
                    },
                    children: <div>{date}</div>
                };
            }
        },
        {
            title: "Fri", dataIndex: 5, key: 5, width: '14.29%', render(date, record) {
                return {
                    props: {
                        style: { background: getHighlights(date, record) }
                    },
                    children: <div>{date}</div>
                };
            }
        },
        {
            title: "Sat", dataIndex: 6, key: 6, width: '14.29%', render(date, record) {
                return {
                    props: {
                        style: { background: getHighlights(date, record) }
                    },
                    children: <div>{date}</div>
                };
            }
        },
        {
            title: "Sun", dataIndex: 7, key: 7, width: '14.29%', render(date, record) {
                return {
                    props: {
                        style: { background: getHighlights(date, record) }
                    },
                    children: <div>{date}</div>
                };
            }
        },
    ];
    const getHighlights = (date, record) => {
        return "unhighlight"
        var w1 = moment(props.date).isoWeek()
        var w2 = moment(props.date).subtract(7, "days").isoWeek()
        var w3 = parseInt(record.key)
        if (date &&
            date.props &&
            (parseInt(date.props.children[0].props.children) <= parseInt(moment(props.date).format("DD")) || parseInt(date.props.children[0].props.children) >= parseInt(moment(props.date).subtract(6, "days").format("DD")))
            && ((parseInt(record.key) === moment(props.date).isoWeek()) || (parseInt(record.key) === moment(props.date).subtract(6, "days").isoWeek()))
        ) {
            return "highlight"
        }
        else {
            return "unhighlight"
        }
    }
    Object.keys(props.data).forEach(session => {
        calendarSessionData[moment(props.data[session][0]['Added_time']).format("MMDDYYYY")] = calendarSessionData[moment(props.data[session][0]['Added_time']).format("MMDDYYYY")] ? (calendarSessionData[moment(props.data[session][0]['Added_time']).format("MMDDYYYY")] + 1) : 1
        calendarSessionData[moment(props.data[session][0]['Added_time']).format("MMYYYY")] = calendarSessionData[moment(props.data[session][0]['Added_time']).format("MMYYYY")] ? (calendarSessionData[moment(props.data[session][0]['Added_time']).format("MMYYYY")] + 1) : 1
    })

    const getCountForSessions = (date) => {
        var indicator = [];
        if (calendarSessionData[date]) {
            var count = calendarSessionData[date] > 5 ? 5 : calendarSessionData[date]
            for (var i = count; i > 0; i--) {
                indicator.push(<StatusGoodSmall color="rgb(176,176,176)" size={widthfactor} />);
            }
            return indicator
        } else {
            indicator.push(<StatusGoodSmall color="rgb(0,0,0,0)" size={widthfactor} />);
            return indicator
        }
    }

    const printLastMonth = () => {
        var calendarDataRaw = {};
        var calendarData = [];
        var dateMax = moment(props.date);
        var dateMin = moment(props.date).subtract(6,"day");
        var month = moment(props.date).subtract(1, "month").format("MMMM");
        var year = moment(props.date).subtract(1, "month").format("YYYY");
        var startMonth = moment(props.date).subtract(1, "month").startOf("month");
        var endMonth = moment(props.date).subtract(1, "month").endOf("month");
        var classDay = "Day"
        for (var m = moment(startMonth); m.isBefore(endMonth); m.add(1, "days")) {
            if (!calendarDataRaw[m.isoWeekYear().toString()+m.format("WW")]) {
                calendarDataRaw[m.isoWeekYear().toString()+m.format("WW")] = {}
                calendarDataRaw[m.isoWeekYear().toString()+m.format("WW")]["key"] = m.isoWeekYear().toString()+m.format("WW");
            }
            if(m.isBetween(dateMin,dateMax, 'days', '[]')){
                classDay="highlightDay"
            }else{
                classDay="Day"
            }
            calendarDataRaw[m.isoWeekYear().toString()+m.format("WW")][m.isoWeekday()] = (<div className={classDay}><span className="Day" onClick={dateselectedlastmonth}>{m.format("DD")}</span><br />{getCountForSessions(m.format("MMDDYYYY"))}</div>)
        }
        calendarData = Object.values(calendarDataRaw)
        return (
            <>
                <p>
                    <div className="calendarHeader" style={window.innerWidth>1000?{ fontSize: "0.85vw" }:{ fontSize: "1.1vw" }}>
                        <Button
                            className="chartPagination"
                            type="text"
                            onClick={() => handlePagination("left")}
                            icon={<CaretLeftOutlined style={window.innerWidth>1000?{ fontSize: "1.2vw" }:{ fontSize: "1.7vw" }} />} />
                        {month},&ensp;{year} <span style={window.innerWidth>1000?{ fontSize: "0.65vw" }:{ fontSize: "0.8vw" }} className="tab">{calendarSessionData[moment(props.date).subtract(1, "month").format("MMYYYY")] ? calendarSessionData[moment(props.date).subtract(1, "month").format("MMYYYY")] : 0}&ensp;Sessions</span></div>
                </p>
                <Table
                    bordered={false}
                    className={window.innerWidth>1000?"calendarWide":"calendar"}
                    rowClassName= {window.innerWidth>1000?"calendarRowWide":"calendarRow"}
                    dataSource={Object.values(calendarDataRaw)}
                    columns={week}
                    pagination={false}
                />
            </>
        );
    }

    const dateselected = (event) => {
        props.selectedDate(moment(props.date).format("MM")+event.target.innerHTML+moment(props.date).format("YYYY"));

    }
    const dateselectedlastmonth = (event) => {
        props.selectedDate(moment(props.date).subtract(1,"month").format("MM")+event.target.innerHTML+moment(props.date).subtract(1,"month").format("YYYY"));
    }
   

    const printCurrentMonth = () => {
        var calendarDataRaw = {};
        var calendarData = [];
        var dateMax = moment(props.date);
        var dateMin = moment(props.date).subtract(6,"day");
        var month = moment(props.date).format("MMMM");
        var year = moment(props.date).format("YYYY")
        var startMonth = moment(props.date).startOf("month")
        var endMonth = moment(props.date).endOf("month")
        var classDay = "Day"
        for (var m = moment(startMonth); m.isBefore(endMonth); m.add(1, "days")) {
            if (!calendarDataRaw[m.isoWeekYear().toString()+m.format("WW")]) {
                calendarDataRaw[m.isoWeekYear().toString()+m.format("WW")] = {}
                calendarDataRaw[m.isoWeekYear().toString()+m.format("WW")]["key"] = m.isoWeekYear().toString()+m.format("WW");
            }
            if(m.isBetween(dateMin,dateMax, 'days', '[]')){
                classDay="highlightDay"
            }else{
                classDay="Day"
            }
            calendarDataRaw[m.isoWeekYear().toString()+m.format("WW")][m.isoWeekday()] = (<div className={classDay}><span className="Day" onClick={dateselected}>{m.format("DD")}</span><br />{getCountForSessions(m.format("MMDDYYYY"))}</div>)
        }
        calendarData = Object.values(calendarDataRaw)
        
        return (
            <>
                <p>
                    <div className="calendarHeader2" style={window.innerWidth>1000?{ fontSize: "0.85vw" }:{ fontSize: "1.1vw" }}>
                        {month},&ensp;{year} <span style={window.innerWidth>1000?{ fontSize: "0.65vw" }:{ fontSize: "0.8vw" }} className="tab">{calendarSessionData[moment(props.date).format("MMYYYY")] ? calendarSessionData[moment(props.date).format("MMYYYY")] : 0}&ensp;Sessions</span></div>
                </p>
                <Table
                    bordered={false}
                    className={window.innerWidth>1000?"calendar2Wide":"calendar2"}
                    rowClassName={window.innerWidth>1000?"calendarRowWide":"calendarRow"}
                    dataSource={Object.values(calendarDataRaw)}
                    columns={week}
                    pagination={false}
                />
                <Button
                    className="chartPagination2"
                    onClick={() => handlePagination("right")}
                    type="text"
                    icon={<CaretRightOutlined style={window.innerWidth>1000?{ fontSize: "1.2vw" }:{ fontSize: "1.7vw" }} />}
                />
                <DoubleLeftOutlined className="righticon" />
            </>
        );
    }

    const handlePagination = (direction) => {
        props.handleMonthPagination(direction,1,"month")
    }

    return (
        <>
            {printLastMonth()}
            {printCurrentMonth()}

        </>
    );
};
export default PCalendar;