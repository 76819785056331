import React, { useEffect, useState } from "react";
import { Button, Card, Table, Form, Input, Modal } from "antd";
import { API, Auth } from "aws-amplify";
import { SyncOutlined } from "@ant-design/icons";
import { providerColumn } from "./ProvidersTable";
import { useHistory } from "react-router-dom";
import { api } from "../../services/link";
import "antd/dist/antd.css";
import "../../App.css";
import Headers from "../../components/Headers";
import Footer from "../../components/Footer";

const Providers = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const cardWidthFactor = window.innerWidth>1000?'80vw':'97vw';
  const modalWidthFactor = window.innerWidth>1000?'35vw':'55vw';
  const fontSizeFactor = window.innerWidth>1000?'0.9vw':'1.6vw';
  const [showAddProvider, setShowAddProvider] = useState(false);
  const [newProviderData, setNewProviderData] = useState({});
  const [providerTableData, setProviderTableData] = useState([]);
  const [userId, setUserId] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    setIsRefresh(true);
    Auth.currentSession()
      .then((user) => {
        setJwtToken(user.idToken.jwtToken);
        setUserId(user.accessToken.payload.username);
        const myInit = {
          headers: {
            Authorization: `Bearer ${user.idToken.jwtToken}`,
          },
          body: {
            username: user.accessToken.payload.username,
            orgName: localStorage.getItem("currentOrgID"),
          },
        };
        API.post(api.apiName, api.providerlist, myInit)
          .then((response) => {
            if (response.type === "success") {
              setIsRefresh(false);
              response.data.providers.forEach((provider) => {
                var prov = {
                  key: provider.Username,
                  UserCreateDate: provider.UserCreateDate,
                  UserLastModifiedDate: provider.UserLastModifiedDate,
                  UserStatus: provider.UserStatus,
                };
                provider.UserAttributes.forEach((attr) => {
                  prov[attr.Name] = attr.Value;
                });
                setProviderTableData((prevState) => [...prevState, prov]);
              });
            } else if (response.type === "unauth") {
              // errorMsg(response.data);
              Modal.error({
                title: "Error!",
                content: response.data,
                onOk() {
                  history.push("/");
                },
              });
            }
          })
          .catch((error) => {
            console.log(error);
            errorMsg(error.message);
          });
      })
      .catch(() => {
        history.push("/login");
      });
  }, [history]);

  // for general error msg
  const errorMsg = (message) => {
    Modal.error({
      title: "Error!",
      content: message,
    });
  };

  const handleChange = (event) => {
    console.log(event.target.name);
    let oldData = newProviderData;
    oldData[event.target.name] = event.target.value;
    setNewProviderData(oldData);
  };

  const closeAddUser = () => {
    setShowAddProvider(false);
  };

  //  integrate the api here I will take care of the inputs
  const onSubmit = (e) => {
    const myInit = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: {
        username: userId,
        orgName: localStorage.getItem("currentOrgID"),
        newProviderData: newProviderData,
      },
    };
    API.post(api.apiName, api.addprovider, myInit)
      .then((response) => {
        if (response.type === "error") {
          errorMsg(response.data);
        } else if (response.type === "unauth") {
          // errorMsg(response.data);
          Modal.error({
            title: "Error!",
            content: response.data,
            onOk() {
              history.push("/");
            },
          });
        } else {
          Modal.success({
            title: "Success!",
            content: response.message,
            onOk() {
              form.resetFields();
              setShowAddProvider(false);
              fetchData();
            },
          });
        }
      })
      .catch((error) => {
        errorMsg(error.message);
      });
  };

  const fetchData = () => {
    setIsRefresh(true);
    setProviderTableData([]);
    const myInit = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: {
        username: userId,
        orgName: localStorage.getItem("currentOrgID"),
      },
    };
    API.post(api.apiName, api.providerlist, myInit)
      .then((response) => {
        if (response.type === "success") {
          setIsRefresh(false);
          response.data.providers.forEach((provider) => {
            var prov = {
              key: provider.Username,
              UserCreateDate: provider.UserCreateDate,
              UserLastModifiedDate: provider.UserLastModifiedDate,
              UserStatus: provider.UserStatus,
            };
            provider.UserAttributes.forEach((attr) => {
              prov[attr.Name] = attr.Value;
            });
            setProviderTableData((prevState) => [...prevState, prov]);
          });
        } else if (response.type === "unauth") {
          // errorMsg(response.data);
          Modal.error({
            title: "Error!",
            content: response.data,
            onOk() {
              history.push("/");
            },
          });
        }
      })
      .catch((error) => {
        errorMsg(error.message);
      });
  };

  return (
    <>
      <div className="App">
        <Headers />
        <div className="TableContainer">
          <Card style={{width:cardWidthFactor}}>
            <div className="buttonContainer">
              <Button
                onClick={() => {
                  setShowAddProvider(true);
                }}
              >
                Enroll Provider
              </Button>

              <Button onClick={fetchData}>
                <SyncOutlined spin={isRefresh} />
                refresh
              </Button>
            </div>
            <Table  dataSource={providerTableData} columns={providerColumn}  className= {window.innerWidth>1000?"table-providerlist":"table-providerlist2"}  />
          </Card>

          {/* for Modal */}
          <Modal
            title="Add a provider"
            visible={showAddProvider}
            onOk={closeAddUser}
            onCancel={closeAddUser}
            width={modalWidthFactor}           
            footer={[
              <Button
                type="primary"
                key="submit"
                onClick={onSubmit}
                size={"large"} style={{ width: "40%", background: "rgb(68,192,192)", borderColor: "white" }}
              >
                Submit
              </Button>,
            ]}
          >
            <Form layout={"vertical"} form={form}>
              <Form.Item
                label="Provider's First Name:"
                name="fname"
                style={{ marginRight: "3%", marginBottom: "2%" }}
              >
                <Input  style={{  fontSize: fontSizeFactor }}  placeholder="Enter provider's First Name" onChange={handleChange} name="fname" />
              </Form.Item>

              <Form.Item
                label="Provider's Last Name:"
                name="lname"
                style={{ marginRight: "3%", marginBottom: "2%" }}
              >
                <Input style={{  fontSize: fontSizeFactor }} placeholder="Enter provider's Last Name" onChange={handleChange} name="lname" />
              </Form.Item>

              <Form.Item
                label="Provider's Email Address:"
                name="email"
                style={{ marginRight: "3%", marginBottom: "2%" }}
              >
                <Input
                  placeholder="Enter provider's email address"
                  onChange={handleChange}
                  name="email" style={{  fontSize: fontSizeFactor }}
                />
              </Form.Item>

              <Form.Item
                label="Provider's Phone Number: (+1 )"
                name="phone"
                style={{ marginRight: "3%", marginBottom: "2%" }}
              >
                <Input
                  placeholder="Enter provider's phone number"
                  onChange={handleChange}
                  name="phone" style={{  fontSize: fontSizeFactor }}
                />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Providers;
