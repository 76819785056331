export const patientsColumn = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.length - b.name.length
  },
  {
    title: "MRN",
    dataIndex: "mrn",
    key: "mrn",
  },
  {
    title: "Date of Enrollment",
    dataIndex: "enroll",
    key: "enroll",
  },
  {
    title: "Device SN",
    dataIndex: "deviceSn",
    key: "deviceSn",
  },
  {
    title: "Provider Assigned",
    dataIndex: "provider",
    key: "provider",
    sorter: (a, b) => a.provider.length - b.provider.length
  },
  {
    title: "Date of Last Session Recorded",
    dataIndex: "lastSession",
    key: "lastSession",
  },
];
