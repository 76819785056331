//page to print patient details
import React, { useState, useEffect, useRef } from "react";
import Headers from "../../components/Headers";
import Footer from "../../components/Footer";
import "../../App.css";
import {
  Card,
  Row,
  Col,
  Table,
  Image,
  Divider,
  Button,
  Collapse,
  Form,
  Modal,
  Input,
  Select,
  DatePicker
} from "antd";
import { API, Auth } from "aws-amplify";
import {
  ComposedChart,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";
import Loader from "react-loader-spinner";
import { api } from "../../services/link";
import "antd/dist/antd.css";
import {
  LeftOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "react-scroll-calendar/build/react-scroll-calendar.css"; // only needs to be imported once
import moment from "moment";
import { useHistory } from "react-router-dom";
import PCalendar from "../../components/calendar";
import { gli, equat, equatL, equatS, fvcequat, fvcequatL, fvcequatS, calcHeightCM, getRaceMap, calcAgeBrackets } from "../../services/glidata";
import PrintV1 from "../../components/exportJS";
import { useReactToPrint } from 'react-to-print';
const { Option } = Select;
const { TextArea } = Input;
//symptom matrix columns
var symptommatrix = [
  "No Symptoms",
  "Respiration",
  "Cough",
  "Wheezing",
  "Trouble Breathing",
  "Sputum Color",
  "Chest",
  "Shortness of Breath",
  "Chest Tightness",
  "Body",
  "Chills",
  "Aches",
  "Fatigue",
  "Loss of Taste/Smell",
  "Fever",
  "Skin",
  "Blotchy Skin",
  "Rash",
];
var symptomMeta = {
  "No Symptoms": { label: "No Symptoms", loc: 1, asset: "Green-Check.png" },
  Cough: {
    label: "Cough",
    loc: 3,
    asset: "001-Present.png",
    asset_meta: {
      "Occasional - I cough every once in a while": { "value": "002-Occasional.png", label: "Occasional" },
      "Persistent - My cough is constant": { "value": "003-Persistent.png", label: "Persistent" },
      "Chronic - I cough every day...": { "value": "004-Chronic.png", label: "Chronic" }
    }
  },
  Wheezing: { label: "Wheezing", loc: 4, asset: "001-Present.png" },
  "Trouble Breathing": {
    label: "Trouble Breathing",
    loc: 5,
    asset: "001-Present.png",
  },
  "Productive Cough": {
    label: "Trouble Breathing",
    loc: 6,
    asset: "001-Present.png",
    asset_meta: {
      "Clear": { "value": "001-Clear.png", label: "Clear" },
      "White": { "value": "002-White.png", label: "White" },
      "Tan": { "value": "003-Tan.png", label: "Tan" },
      "Yellow": { "value": "004-Yellow.png", label: "Yellow" },
      "Green": { "value": "005-Green.png", label: "Green" },
      "Brown": { "value": "006-Brown.png", label: "Brown" },
      "Bloody": { "value": "007-Bloody.png", label: "Bloody" },
      "Blood Tinged Tissue": { "value": "008-Blood-Tinged.png", label: "Blood-Tinged" }
    }
  },
  "Shortness of Breath": {
    label: "Shortness of Breath",
    loc: 8,
    asset: "001-Present.png",
    asset_meta: {
      0: { "value": "000-Nothing-at-all.png", label: "Nothing at all" },
      1: { "value": "001-Just-Noticeable.png", label: "Just Noticeable" },
      2: { "value": "002-Very-Slight.png", label: "Very Slight" },
      3: { "value": "003-Slight.png", label: "Slight" },
      4: { "value": "004-Moderate.png", label: "Moderate" },
      5: { "value": "005-Some-Difficulty.png", label: "Some Difficulty" },
      6: { "value": "006-Severe.png", label: "Severe" },
      7: { "value": "007-Very-Severe.png", label: "Very Severe" },
      8: { "value": "008-Almost-Maximal.png", label: "Almost Maximal" },
      9: { "value": "009-Maximal.png", label: "Maximal" },
      10: { "value": "010-Panic-Level.png", label: "Panic Level" }
    }
  },
  "Pain / Pressure / Tightness in Chest": {
    label: "Chest Tightness",
    loc: 9,
    asset: "001-Present.png",
  },
  Chills: { label: "Chills", loc: 11, asset: "001-Present.png" },
  Aches: { label: "Aches", loc: 12, asset: "001-Present.png" },
  Fatigue: { label: "Fatigue", loc: 13, asset: "001-Present.png" },
  "Loss of Taste or Smell": {
    label: "Loss of Taste/Smell",
    loc: 14,
    asset: "001-Present.png",
  },
  "Blotchy Skin": { label: "Blotchy Skin", loc: 17, asset: "001-Present.png" },
  Rash: { label: "Rash", loc: 18, asset: "001-Present.png" },
};

var days = 8;

const PatientDetail = ({ location }) => {
  const userName = location.state.name;
  const userMRN = location.state.mrn;
  const email = location.state.email;
  const phone = location.state.phone;
  let [openPanels, setOpenPanels] = React.useState([]);
  const [loadingPageStatus, setpageStatus] = useState(true);
  const [patientInfoStatus, setpatientInfoStatus] = useState(false);
  const [noUserData, setnoUserData] = useState("nodata")
  const [userData, setUserData] = useState([]);
  const [userSessionData, setuserSessionData] = useState([]);
  const [lungFunctionColumn, setLungFunctionColumn] = useState([]);
  const [sessionIdsForDates, setsessionIdsForDates] = useState({});
  const [sessionIds, setsessionIds] = useState([]);
  const [respiratoryRateData, setRespiratoryRateData] = useState({});
  const [symptommsData, setSymptomsData] = useState({});
  const [temperatureData, setTemperatureData] = useState({});
  const [reportStartDate, setreportStartDate] = useState();
  const [showEditPatient, setshowEditPatient] = useState(false);
  const [showPatientExport, setshowPatientExport] = useState(false);
  const [tnewPatientData, settNewPatientData] = useState({});
  const [form] = Form.useForm();
  const [exportNotes, setexportNotes] = useState("")
  const [formexport] = Form.useForm();
  const [newPatientData, setNewPatientData] = useState({});
  const [calendarDate, setcalendarDate] = useState(moment());
  const [reportRangeDate, setReportRangeDate] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [fvcM, setfvcM] = useState(0.0)
  const [fvcLLN, setfvcLLN] = useState(0.0)
  const [M, setM] = useState(0.0)
  const [LLN, setLLN] = useState(0.0)

  const [lungFunctionData, setLungFunctionData] = useState([]);
  const [buttonVisiblity, setButtonVisiblity] = useState({
    pefvisible: { 'state': true, 'label': 'PEF' },
    fev10visible: { 'state': false, 'label': 'FEV10' },
    fev1visible: { 'state': false, 'label': 'FEV1' },
    fev75visible: { 'state': false, 'label': 'FEV75' },
    fev2575visible: { 'state': false, 'label': 'FEF2575' },
    fev1fev10visible: { 'state': false, 'label': 'FEV1_FEV10' },
  });
  let history = useHistory();

  //fetch data for patient
  useEffect(() => {
    Auth.currentSession()
      .then((user) => {
        const myInit = {
          headers: {
            Authorization: `Bearer ${user.idToken.jwtToken}`,
          },
          body: {
            userselected: location.state.key,
          },
        };
        API.post(api.apiName, api.patientreport, myInit)
          .then((response) => {
            let data = response.Items[0];
            setUserData({
              "Date Of Birth": data.DOB ? data.DOB : '',
              "Date Enrolled": data.Created ? moment(data.Created).format("MM-DD-YYYY") : '',
              "Height(in)": data.Height || "",
              "Weight(lb.)": data.Weight || "",
              Race: data.Race || "",
              "Device SN": data.DeviceAssigned || "",
              "Date Device Issued": moment(data.Created).format("MM-DD-YYYY") || "",
              "Patient Contact Information": (email ? email : '') + " " + (phone ? phone : ''),
              "Diagnoses ": data.LungConditions || " ",
              Medications: data.Medications || "",
              "Additional Therapies": data.Therapies || "",
              Severity: data.Severity || "",
            });
            setNewPatientData({
              "mrn": userMRN ? userMRN : '',
              "age": data.DOB ? data.DOB : '',
              "fname": userName ? userName.split(" ")[0] : '',
              "lname": userName ? userName.split(" ")[1] : '',
              "email": email ? email : '',
              "phone": phone ? phone : '',
              "sex": data.Sex ? data.Sex : '',
              "race": data.Race || "",
              "height": data.Height || "",
              "weight": data.Weight || "",
              "serialno": data.DeviceAssigned || "",
              "lungcondition": data.LungConditions || " ",
              "severity": data.Severity || "",
              "medications": data.Medications || "",
              "therapies": data.Therapies || "",
            });
            var ageyears = (calcAgeGLI(data.DOB));
            var agesKey = calcAgeBrackets(ageyears, gli);
            var sexatbirth = data.Sex === 'Male' ? 'male' : 'female';
            var racemap = getRaceMap(data.Race);
            var heightcm = calcHeightCM(data.Height);
            var mspline = gli["fev1"][sexatbirth][agesKey[0]]["Mspline"] + ((ageyears - agesKey[0]) / 0.25) * (gli["fev1"][sexatbirth][agesKey[1]]["Mspline"] - (gli["fev1"][sexatbirth][agesKey[0]]["Mspline"]));
            var sspline = gli["fev1"][sexatbirth][agesKey[0]]["Sspline"] + ((ageyears - agesKey[0]) / 0.25) * (gli["fev1"][sexatbirth][agesKey[1]]["Sspline"] - (gli["fev1"][sexatbirth][agesKey[1]]["Sspline"]));
            var M = Math.exp(equat["a0"] + equat["a1"] * Math.log(heightcm) + equat["a2"] * Math.log(ageyears) + equat["a3"] * racemap["a3"] + equat["a4"] * racemap["a4"] + equat["a5"] * racemap["a5"] + equat["a6"] * racemap["a6"] + mspline);
            setM(M);
            var S = Math.exp(equatS["p0"] + equatS["p1"] * Math.log(ageyears) + equatS["p2"] * racemap["a3"] + equatS["p3"] * racemap["a4"] + equatS["p4"] * racemap["a5"] + equatS["p5"] * racemap["a6"] + sspline);
            var L = equatL["q0"] + equatL["q1"] * Math.log(ageyears);
            var LLN = Math.exp(Math.log(1 - 1.644 * L * S) / L + Math.log(M));
            setLLN(LLN);
            var fvcmspline = gli["fvc"][sexatbirth][agesKey[0]]["Mspline"] + ((ageyears - agesKey[0]) / 0.25) * (gli["fvc"][sexatbirth][agesKey[1]]["Mspline"] - (gli["fvc"][sexatbirth][agesKey[0]]["Mspline"]));
            var fvcsspline = gli["fvc"][sexatbirth][agesKey[0]]["Sspline"] + ((ageyears - agesKey[0]) / 0.25) * (gli["fvc"][sexatbirth][agesKey[1]]["Sspline"] - (gli["fvc"][sexatbirth][agesKey[1]]["Sspline"]));
            var fvcM = Math.exp(fvcequat["a0"] + fvcequat["a1"] * Math.log(heightcm) + fvcequat["a2"] * Math.log(ageyears) + fvcequat["a3"] * racemap["a3"] + fvcequat["a4"] * racemap["a4"] + fvcequat["a5"] * racemap["a5"] + fvcequat["a6"] * racemap["a6"] + fvcmspline);
            setfvcM(fvcM);
            var fvcS = Math.exp(fvcequatS["p0"] + fvcequatS["p1"] * Math.log(ageyears) + fvcequatS["p2"] * racemap["a3"] + fvcequatS["p3"] * racemap["a4"] + fvcequatS["p4"] * racemap["a5"] + fvcequatS["p5"] * racemap["a6"] + fvcsspline);
            var fvcL = fvcequatL["q0"];
            var fvcLLN = Math.exp(Math.log(1 - 1.645 * fvcL * fvcS) / fvcL + Math.log(fvcM));
            setfvcLLN(fvcLLN)
            if (data.sessions && data.sessions.length > 0) {
              setuserSessionData(...[], data.sessions);
              setRespiratoryRateData(data.respiratory);
              setSymptomsData(data.symptoms);
              setTemperatureData(data.temperature);

              let columnData = [];
              let datesforSessionColumns = [];
              for (let i = days - 1; i >= 0; i--) {
                columnData.push({
                  title:
                    i !== 0
                      ? moment(
                        data.sessions[data.sessions.length - 1][0].Added_time
                      )
                        .subtract(i - 1, "day")
                        .format("ddd DD")
                      : "",
                  dataIndex:
                    i !== 0
                      ? moment(
                        data.sessions[data.sessions.length - 1][0].Added_time
                      )
                        .subtract(i - 1, "day")
                        .format("MMDDYYYY")
                      : "0",
                  key:
                    i !== 0
                      ? moment(
                        data.sessions[data.sessions.length - 1][0].Added_time
                      )
                        .subtract(i - 1, "day")
                        .format("MMDDYYYY")
                      : "0",
                });
                datesforSessionColumns.push(
                  i !== 0
                    ? moment(
                      data.sessions[data.sessions.length - 1][0].Added_time
                    )
                      .subtract(i - 1, "day")
                      .format("MMDDYYYY")
                    : ""
                );
              }
              let sessionIdsForSessionData = {};
              let sessionIds = [];
              for (var j in data.sessions) {
                var dateCheck = moment(data.sessions[j][0].Added_time).format(
                  "MMDDYYYY"
                );
                if (datesforSessionColumns.includes(dateCheck)) {
                  sessionIdsForSessionData[j] = dateCheck;
                  sessionIds.push(j);
                }
              }
              setsessionIds(...[], sessionIds);
              setsessionIdsForDates(sessionIdsForSessionData);
              setLungFunctionColumn(...[], columnData);
              setreportStartDate(data.sessions[data.sessions.length - 1][0].Added_time)
              setpageStatus(false);
              setnoUserData("found")
            } else {
              setpageStatus(false);
              setnoUserData("nosessiondata")
            }
          })
          .catch((error) => {
            console.log(error);
            setpageStatus(false);
            setnoUserData("nodata")
          });
      })
      .catch(() => {
        setpageStatus(false);
        localStorage.clear();
        Auth.signOut();
        history.replace("/login");
      });
  }, []);

  //handle individual chart in lung function table
  const lungFunction = (session, data) => {
    var data3 = { 0: buttons() };
    session.forEach((g) => {
      var cur = moment(data[g][0].Added_time).format("MMDDYYYY");
      var value = {};
      value["label"] = g;
      value["PEF"] = Math.max(
        parseInt(data[g][0].PEF),
        parseInt(data[g][1].PEF),
        parseInt(data[g][2].PEF)
      );
      value["FEV1"] = Math.max(
        parseInt(data[g][0].FEV1),
        parseInt(data[g][1].FEV1),
        parseInt(data[g][2].FEV1)
      );
      value["FEV10"] = Math.max(
        parseInt(data[g][0].FEV10),
        parseInt(data[g][1].FEV10),
        parseInt(data[g][2].FEV10)
      );
      value["FEV75"] = Math.max(
        parseInt(data[g][0].FEV75),
        parseInt(data[g][1].FEV75),
        parseInt(data[g][2].FEV75)
      );
      value["FEF2575"] = Math.max(
        parseInt(data[g][0].FEF2575),
        parseInt(data[g][1].FEF2575),
        parseInt(data[g][2].FEF2575)
      );
      value["FEV1_FEV10"] = Math.max(
        parseInt(data[g][0].FEV1_FEV10),
        parseInt(data[g][1].FEV1_FEV10),
        parseInt(data[g][2].FEV1_FEV10)
      );
      data3[cur] = data3[cur] ? data3[cur].concat([value]) : [value];
    });
    const barsizefactor = window.innerWidth > 1000 ? 1 : 2
    const graphheightfactor = window.innerWidth > 1000 ? 350 : 198

    var count = 0;
    Object.keys(buttonVisiblity).forEach(b => {
      if (buttonVisiblity[b].state) {
        count += 1
      }
    })
    for (var h in data3) {
      if (h !== "0") {
        if (data3[h].length > 0) {
          data3[h] = (

            <ResponsiveContainer width={'100%'} height={graphheightfactor}>
              <BarChart
                barGap={(2 / barsizefactor)}
                barSize={count > 1 ? (5 / barsizefactor) : (9 / barsizefactor)}
                height={graphheightfactor}
                data={data3[h].reverse().slice(0, 4).reverse()}
                syncId={h}
              >
                <XAxis dataKey="label" tick={false} hide={true} />
                <YAxis hide={true} domain={[0, 5]} hide={true} />
                <Tooltip
                  wrapperStyle={{ backgroundColor: "white", zIndex: "1" }}
                  cursor={false}
                  allowEscapeViewBox={{ x: true, y: false }}
                  content={<CustomTooltip />}
                />
                {buttonVisiblity.pefvisible.state ? <Bar dataKey={"PEF"} fill="rgb(3,117,128)" /> : ''}
                {buttonVisiblity.fev10visible.state ? <Bar dataKey={"FEV10"} fill="rgb(3,117,128) " /> : ''}
                {buttonVisiblity.fev1visible.state ? <Bar dataKey={"FEV1"} fill="rgb(68,192,192)" /> : ''}
                {buttonVisiblity.fev75visible.state ? <Bar dataKey={"FEV75"} fill="rgb(20,65,86)" /> : ''}
                {buttonVisiblity.fev2575visible.state ? <Bar dataKey={"FEF2575"} fill="rgb(3,117,128)" /> : ''}
                {buttonVisiblity.fev1fev10visible.state ? <Bar dataKey={"FEV1_FEV10"} fill="rgb(3,117,128)" /> : ''}
              </BarChart>
            </ResponsiveContainer>
          );
        }
      }
    }
    data3["key"] = '1'
    return [data3];
  };

  const calcAgeGLI = (age) => {
    return moment().diff(moment(age).format("YYYY-MM-DD"), 'days') / 365.2425
  }

 
  //handle hover over lung function chart
  const CustomTooltip = ({ active, payload, label }) => {
    if (payload[0]) {
    }
    if (active) {
      
      //var fvczscore = (Math.pow((newPatientData["fvc"]/fvcM),fvcL)-1)/(fvcL*fvcS)

      //var zscore = (Math.pow((newPatientData["fev1"]/M),L)-1)/(L*S)
      return (
        <div className="custom-tooltip">
          <div className="tooltipLabel">Date: {userSessionData[label] ? moment(userSessionData[label][0].Added_time).format("MM/DD/YYYY") : ''}</div>
          <div className="tooltipLabel2">Time: {userSessionData[label] ? moment(userSessionData[label][0].Added_time).format('h:mma') : ''}</div>
          <div className="tooltipText">PEF (L/min): {payload[0] ? parseFloat(payload[0].payload.PEF) : ''} L/min</div>
          <div className="tooltipText">FVC (L): {payload[0] ? parseFloat(payload[0].payload.FEV10) / 100 : ''}L</div>
          <div className="tooltipText">GLI% Predicted: {payload[0] ? ((parseFloat(payload[0].payload.FEV10) / 100) / fvcM * 100).toFixed(1) : ''}%</div>
          <div className="tooltipText">LLN: {fvcLLN.toFixed(4)} L</div>
          <div className="tooltipText">FEV1 (L): {payload[0] ? parseFloat(payload[0].payload.FEV1) / 100 : ''}L</div>
          <div className="tooltipText">GLI% Predicted: {payload[0] ? ((parseFloat(payload[0].payload.FEV1) / 100) / M * 100).toFixed(1) : ''}%</div>
          <div className="tooltipText">LLN: {LLN.toFixed(4)} L</div>
          <div className="tooltipText">FEV0.75 (L): {payload[0] ? parseFloat(payload[0].payload.FEF2575) / 100 : ''}L</div>
        </div>
      );
    }

    return null;
  };

  //report pagination (above lung function)
  const reportPagination = (styled) => {
    var t = userSessionData;
    if (userSessionData.length > 0) {
      var start = moment(
        reportStartDate
      )
        .subtract(6, "day")
        .format("MMM DD");
      var end = moment(
        reportStartDate
      ).format("MMM DD, YYYY");
      return (
        <span style={styled}>
          {" "}
          {start} <b>-</b> {end}{" "}
        </span>
      );
    } else {
      return <span> </span>;
    }
  };

  //handle table for lung function 
  const lungFunctionChart = () => {
    return (
      <>
        <div className="row-container1" >
          <span className="row-containerleft">Device Data</span>

          <span className="row-containerright">
            Lung Function Test
          </span>
        </div>
        <div className="row-container">
          <Table
            bordered={false}
            className="table-graph"
            dataSource={lungFunction(sessionIds, userSessionData)}
            columns={lungFunctionColumn}
            pagination={false}
          />
        </div>
        <br />
        <div style={{ fontSize: 'max(0.85vw,0.62em)', textAlign: "left" }}>*FEV 10 is the surrogate for FVC in this chart</div>
      </>
    );
  };

  //handle table for respiratory rate and temperature
  const respiratoryRate = () => {
    return (
      <>
        <div
          className="row-container1"
          style={{ marginTop: "3%" }}
        >
          <span className="row-containerleft">Self-Reported</span>
          <span className="row-containerright1">
            Temperature{" "}
          </span>
          <span className="row-containerright1">
            Respiratory Rate
          </span>
          <span className="row-containerright1">
            Temperature &amp; Respiratory Rate
          </span>
        </div>
        <div className="row-container">
          <Table
            bordered={true}
            className="table-graph2"
            dataSource={getRespiratoryRateData(
              "Respiratory Rate",
              "Temperature"
            )}
            columns={lungFunctionColumn}
            pagination={false}
          />
        </div>
      </>
    );
  };

  //handle individual chart in respiratory rate and temperature table
  const getRespiratoryRateData = (params, params2) => {
    var data3 = { 0: textLabel() };
    sessionIds.forEach((g) => {
      var cur = sessionIdsForDates[g];
      var value = {};
      value["label"] = g;
      value[params] = respiratoryRateData[g]
        ? respiratoryRateData[g].respiratoryrate
        : 0;
      value[params2] = temperatureData[g]
        ? parseFloat(temperatureData[g].temperature)
        : 0.0;
      data3[cur] = data3[cur] ? data3[cur].concat([value]) : [value];
    });
    const graphheightfactor = window.innerWidth > 1000 ? 250 : 170
    const barsizefactor = window.innerWidth > 1000 ? 1 : 2

    for (var h in data3) {
      if (h !== "0") {
        if (data3[h].length > 0) {
          data3[h] = (
            <ResponsiveContainer width={'100%'} height={graphheightfactor}>
              <ComposedChart

                height={graphheightfactor}
                syncId={h}
                data={data3[h].reverse().slice(0, 4).reverse()}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="label" tick={false} hide={true} />
                <Tooltip
                  wrapperStyle={{ backgroundColor: "white", zIndex: "1" }}
                  cursor={false}
                  allowEscapeViewBox={{ x: true, y: false }}
                  content={<CustomTooltip2 />}
                />
                <Bar dataKey={params} barSize={10 / barsizefactor} fill="rgb(171,227,226)" />
                <Bar dataKey={params2} barSize={8 / barsizefactor} fill="rgb(241,63,78)" />
              </ComposedChart>
            </ResponsiveContainer>
          );
        }
      }
    }
    data3["key"] = '1'
    return [data3];
  };

  //handle hover over respiratory rate and temperature chart
  const CustomTooltip2 = ({ active, payload, label }) => {
    if (payload[0]) {
    }
    if (active) {
      return (
        <div className="custom-tooltip2">
          <div className="tooltipLabel">Date: {userSessionData[label] ? moment(userSessionData[label][0].Added_time).format("MM/DD/YYYY") : ''}</div>
          <div className="tooltipLabel2">Time: {userSessionData[label] ? moment(userSessionData[label][0].Added_time).format('h:mma') : ''}</div>
          <div className="tooltipText">Temperature: {payload[0] ? payload[0].payload.Temperature : ''} &#176;F</div>
          <div className="tooltipText">Respiratory Rate: {payload[0] ? payload[0].payload["Respiratory Rate"] : ''}</div>
        </div>
      );
    }

    return null;
  };

  //handle highlight of titles in symptom matrix
  const getBackground = (index) => {
    if (index === 1 || index === 6 || index === 9 || index === 15) {
      return true;
    } else {
      return false;
    }
  };
  //handle table for symptom matrix
  const symptomMatrix = () => {
    return (
      <>
        <div style={window.innerWidth > 1000 ? { marginTop: '23vw' } : { marginTop: '36vw' }}
          className="row-container2"
        >
          <span className="row-containerleft" >Self-Reported</span>
          <span className="row-containerright">
            Patient Symptoms
          </span>
        </div>
        <div className="row-container">
          <Table
            bordered={false}
            rowClassName={(record, index) =>
              getBackground(index) ? "table-row-highlight" : ""
            }
            className={window.innerWidth > 1000 ? "table-graph3Wide" : "table-graph3"}
            dataSource={getSymptomMatrixData()}
            columns={lungFunctionColumn}
            pagination={false}
          />
        </div>
      </>
    );
  };
  //handle data parsing for image asset for symptom matrix
  const getSymptomMatrixData = () => {
    var dataSource6 = [];
    var test = {};
    var dataSource5 = {};
    if (Object.keys(symptommsData).length > 0) {
      sessionIds.forEach((j) => {
        var cur = sessionIdsForDates[j];
        if (symptommsData[j] && cur) {
          Object.keys(symptommsData[j]).forEach((k) => {
            if (
              symptommsData[j][k] === "true" || symptommsData[j][k] === true ||
              k === "Rash" ||
              k === "Chills"
            ) {
              if (!test[symptomMeta[k].loc]) {
                test[symptomMeta[k].loc] = {
                  [cur]: [imageAsset(symptomMeta[k] ? symptomMeta[k].asset : "001-Present.png")],
                };
              } else if (!test[symptomMeta[k].loc][cur]) {
                test[symptomMeta[k].loc][cur] = [
                  imageAsset(symptomMeta[k] ? symptomMeta[k].asset : "001-Present.png"),
                ];
              } else {
                test[symptomMeta[k].loc][cur].push(
                  imageAsset(symptomMeta[k] ? symptomMeta[k].asset : "001-Present.png")
                );
              }
            } else if (k === "Cough" || k === "Shortness of Breath") {
              if (!test[symptomMeta[k].loc]) {
                test[symptomMeta[k].loc] = {
                  [cur]: [imageAsset(symptomMeta[k].asset_meta[symptommsData[j][k]] ? symptomMeta[k].asset_meta[symptommsData[j][k]].value : "001-Present.png")],
                };
              } else if (!test[symptomMeta[k].loc][cur]) {
                test[symptomMeta[k].loc][cur] = [
                  imageAsset(symptomMeta[k].asset_meta[symptommsData[j][k]] ? symptomMeta[k].asset_meta[symptommsData[j][k]].value : "001-Present.png"),
                ];
              } else {
                test[symptomMeta[k].loc][cur].push(
                  imageAsset(symptomMeta[k].asset_meta[symptommsData[j][k]] ? symptomMeta[k].asset_meta[symptommsData[j][k]].value : "001-Present.png")
                );
              }
            } else if (k === "Productive Cough") {
              if (!test[symptomMeta[k].loc]) {
                test[symptomMeta[k].loc] = {
                  [cur]: [imageAsset(symptomMeta[k].asset_meta[symptommsData[j][k][0]] ? symptomMeta[k].asset_meta[symptommsData[j][k][0]].value : "001-Present.png")],
                };
              } else if (!test[symptomMeta[k].loc][cur]) {
                test[symptomMeta[k].loc][cur] = [
                  imageAsset(symptomMeta[k].asset_meta[symptommsData[j][k][0]] ? symptomMeta[k].asset_meta[symptommsData[j][k][0]].value : "001-Present.png"),
                ];
              } else {
                test[symptomMeta[k].loc][cur].push(
                  imageAsset(symptomMeta[k].asset_meta[symptommsData[j][k][0]] ? symptomMeta[k].asset_meta[symptommsData[j][k][0]].value : "001-Present.png")
                );
              }
            }
          });
        } else if (cur) {
          test[symptomMeta["No Symptoms"].loc] = {
            [cur]: [imageAsset(symptomMeta["No Symptoms"].asset)],
          };
        }
        else {
          dataSource5[cur] = "No Symptoms";
        }
      });
    }
    dataSource5["0"] = (
      <span style={window.innerWidth > 1000 ? { fontSize: '0.88vw' } : { fontSize: '1.4vw' }} className={getSymptomLabelStyle(1)}>{symptommatrix[1 - 1]}</span>
    );
    dataSource6.push(dataSource5);
    var dataSource4 = [];
    for (var m = 1; m <= 18; m++) {
      var dataSource2 = {};
      var data3 = [];
      if (test[m]) {
        Object.keys(test[m]).forEach((j) => {
          data3 = [];
          test[m][j].forEach((l) => {
            data3.push(l);
          });
          dataSource2[j] = data3;
        });
      }
      dataSource2["0"] = (
        <span style={window.innerWidth > 1000 ? { fontSize: '0.88vw' } : { fontSize: '1.4vw' }} className={getSymptomLabelStyle(m)}>{symptommatrix[m - 1]}</span>
      );
      dataSource4["key"] = toString(m)
      dataSource4.push(dataSource2);
    }
    return dataSource4;
  };

  //handle image asset for symptom matrix
  const imageAsset = (image) => {
    let imageurl = require("../../assets/icons/" + image);
    return (
      <Image
        className="iconsClass"
        width={window.innerWidth > 1000 ? "1.8vw" : "2.6vw"}
        src={imageurl.default}
        preview={false}
        alt="image not found"
      />
    );
  };

  //handle label style symptom matrix
  const getSymptomLabelStyle = (index) => {
    if (index === 1) {
      return "noSymptom";
    } else if (index === 2 || index === 7 || index === 10 || index === 16) {
      return "sectionLabel";
    } else {
      return "symptomLabels";
    }
  };

  //buttons for lung function
  const buttons = () => {
    const widthfactor = window.innerWidth > 1000 ? '11.5vw' : '13.5vw';
    return (
      <div className="col-button">
        <Button
          ghost={true}
          style={{ width: widthfactor }}
          className={
            buttonVisiblity.pefvisible.state
              ? "pef-button-selected"
              : "button-unselected"
          }
          onClick={() => handleLungFunctionChart("pefvisible")}
        >
          <span className="buttonText">PEF</span>
        </Button>
        <Button
          ghost={true}
          style={{ width: widthfactor }}
          className={
            buttonVisiblity.fev10visible.state
              ? "pef-button-selected"
              : "button-unselected"
          }
          onClick={() => handleLungFunctionChart("fev10visible")}
        >
          <span className="buttonText">FVC(L)</span>
        </Button>
        <Button
          ghost={true}
          style={{ width: widthfactor }}
          className={
            buttonVisiblity.fev1visible.state
              ? "fev1-button-selected"
              : "button-unselected"
          }
          onClick={() => handleLungFunctionChart("fev1visible")}
        >
          <span className="buttonText">FEV 1(L)</span>
        </Button>
        <Button
          ghost={true}
          style={{ width: widthfactor }}
          className={
            buttonVisiblity.fev75visible.state
              ? "fev75-button-selected"
              : "button-unselected"
          }
          onClick={() => handleLungFunctionChart("fev75visible")}
        >
          <span className="buttonText">FEV 0.75(L)</span>
        </Button>
        <Button
          ghost={true}
          style={{ width: widthfactor }}
          className={
            buttonVisiblity.fev2575visible.state
              ? "pef-button-selected"
              : "button-unselected"
          }
          onClick={() => handleLungFunctionChart("fev2575visible")}
        >
          <span className="buttonText">FEV 25/75 (L/sec)</span>
        </Button>
        <Button
          ghost={true}
          style={{ width: widthfactor }}
          className={
            buttonVisiblity.fev1fev10visible.state
              ? "pef-button-selected"
              : "button-unselected"
          }
          onClick={() => handleLungFunctionChart("fev1fev10visible")}
        >
          <span className="buttonText">FEV1/FVC</span>
        </Button>
      </div>
    );
  };

  //handle visibility for lung function
  const handleLungFunctionChart = (val) => {
    var visibilityButtons = buttonVisiblity;
    if (val === "pefvisible" || val === "fev2575visible" || val === "fev1fev10visible") {
      for (var button in buttonVisiblity) {
        buttonVisiblity[button].state = false
      }
      visibilityButtons[val].state = !visibilityButtons[val].state
    } else {
      var c = 0
      for (var button in visibilityButtons) {
        if (visibilityButtons[button].state) {
          c += 1
        }
      }
      if (c > 1) {
        visibilityButtons[val].state = !visibilityButtons[val].state

      } else {
        visibilityButtons[val].state = visibilityButtons[val].state ? visibilityButtons[val].state : !visibilityButtons[val].state
      }
      buttonVisiblity["pefvisible"].state = false; buttonVisiblity["fev2575visible"].state = false; buttonVisiblity["fev1fev10visible"].state = false;
    }
    setButtonVisiblity(visibilityButtons);
    setLungFunctionData(...[], lungFunction(sessionIds, userSessionData));
  };

  //handle y-axis range for respiratory rate
  const textLabel = () => {
    return (
      <div className="col-label-text" >
        <h5 style={window.innerWidth > 1000 ? { fontSize: '0.8vw' } : { fontSize: '1vw' }}>50</h5>
        <h5 style={window.innerWidth > 1000 ? { fontSize: '0.8vw' } : { fontSize: '1vw' }}>40</h5>
        <h5 style={window.innerWidth > 1000 ? { fontSize: '0.8vw' } : { fontSize: '1vw' }}>30</h5>
        <h5 style={window.innerWidth > 1000 ? { fontSize: '0.8vw' } : { fontSize: '1vw' }}>20</h5>
        <h5 style={window.innerWidth > 1000 ? { fontSize: '0.8vw' } : { fontSize: '1vw' }}>10</h5>
      </div>
    );
  };

  //handle session count for pagination
  const sessionCount = (num) => {
    var ses_cnt = 0;
    if (userSessionData) {
      for (var d = 0; d < userSessionData.length; d++) {
        if (
          moment(userSessionData[d][0].Added_time) <=
          moment(reportStartDate) &&
          moment(userSessionData[d][0].Added_time) >=
          moment(
            reportStartDate
          ).subtract(num, "day")
        ) {
          ses_cnt += 1;
        }
      }
      return ses_cnt;
    } else {
      return 0;
    }
  };
  //handle pagination function
  const handlePaginationCalender = (dateSel) => {
    let page = reportStartDate

    for (var dat = 0; dat < userSessionData.length; dat++) {
      if (moment(dateSel, "MMDDYYYY") < moment(userSessionData[dat][0].Added_time)) {
        page = userSessionData[dat][0].Added_time
        break;
      }
    }

    setreportStartDate(page)
    let columnData = [];
    let datesforSessionColumns = [];
    for (let i = days - 1; i >= 0; i--) {
      columnData.push({
        title:
          i !== 0
            ? moment(
              page
            )
              .subtract(i - 1, "day")
              .format("ddd DD")
            : "",
        dataIndex:
          i !== 0
            ? moment(
              page
            )
              .subtract(i - 1, "day")
              .format("MMDDYYYY")
            : "0",
        key:
          i !== 0
            ? moment(
              page
            )
              .subtract(i - 1, "day")
              .format("MMDDYYYY")
            : "0",
      });
      datesforSessionColumns.push(
        i !== 0
          ? moment(
            page
          )
            .subtract(i - 1, "day")
            .format("MMDDYYYY")
          : ""
      );
    }
    let sessionIdsForSessionData = {};
    let sessionIds = [];
    for (var j in userSessionData) {
      var dateCheck = moment(userSessionData[j][0].Added_time).format(
        "MMDDYYYY"
      );
      if (datesforSessionColumns.includes(dateCheck)) {
        sessionIdsForSessionData[j] = dateCheck;
        sessionIds.push(j);
      }
    }
    setsessionIds(...[], sessionIds);
    setsessionIdsForDates(sessionIdsForSessionData);
    setLungFunctionColumn(...[], columnData);
  };

  //handle pagination function
  const handlePagination = (direction, distance, distance_type) => {
    let page = reportStartDate
    if (direction === "left") {
      for (var dat = userSessionData.length - 1; dat >= 0; dat--) {
        if (moment(reportStartDate).subtract(distance, distance_type) > moment(userSessionData[dat][0].Added_time)) {
          page = userSessionData[dat][0].Added_time
          break;
        }
      }
    } else {
      for (var dat = 0; dat < userSessionData.length; dat++) {
        if (moment(reportStartDate) < moment(userSessionData[dat][0].Added_time)) {
          page = userSessionData[dat][0].Added_time
          break;
        }
      }
      for (var dat = userSessionData.length - 1; dat >= 0; dat--) {
        if (moment(page).add(distance, distance_type) > moment(userSessionData[dat][0].Added_time)) {
          page = userSessionData[dat][0].Added_time
          break;
        }
      }
    }
    setreportStartDate(page)
    let columnData = [];
    let datesforSessionColumns = [];
    for (let i = days - 1; i >= 0; i--) {
      columnData.push({
        title:
          i !== 0
            ? moment(
              page
            )
              .subtract(i - 1, "day")
              .format("ddd DD")
            : "",
        dataIndex:
          i !== 0
            ? moment(
              page
            )
              .subtract(i - 1, "day")
              .format("MMDDYYYY")
            : "0",
        key:
          i !== 0
            ? moment(
              page
            )
              .subtract(i - 1, "day")
              .format("MMDDYYYY")
            : "0",
      });
      datesforSessionColumns.push(
        i !== 0
          ? moment(
            page
          )
            .subtract(i - 1, "day")
            .format("MMDDYYYY")
          : ""
      );
    }
    let sessionIdsForSessionData = {};
    let sessionIds = [];
    for (var j in userSessionData) {
      var dateCheck = moment(userSessionData[j][0].Added_time).format(
        "MMDDYYYY"
      );
      if (datesforSessionColumns.includes(dateCheck)) {
        sessionIdsForSessionData[j] = dateCheck;
        sessionIds.push(j);
      }
    }
    setsessionIds(...[], sessionIds);
    setsessionIdsForDates(sessionIdsForSessionData);
    setLungFunctionColumn(...[], columnData);
  };
  //handle legend for symptom matrix
  const symptomMatrixLegend = () => {
    var dat = [];
    var legendDat = {};
    Object.keys(symptomMeta["Shortness of Breath"].asset_meta).forEach(asset => {
      dat.push(imageAsset(symptomMeta["Shortness of Breath"].asset_meta[asset].value))
      dat.push(<span className="legendText"> = </span>)
      dat.push(<span className="legendText">{symptomMeta["Shortness of Breath"].asset_meta[asset].label}</span>)
      dat.push(<br />)
    })
    legendDat["shortnessofbreath"] = dat
    dat = []
    dat.push(imageAsset("001-Present.png"))
    dat.push(<span className="legendText"> = Present</span>)
    dat.push(<br />)
    Object.keys(symptomMeta["Cough"].asset_meta).forEach(asset => {
      dat.push(imageAsset(symptomMeta["Cough"].asset_meta[asset].value))
      dat.push(<span className="legendText"> = </span>)
      dat.push(<span className="legendText">{symptomMeta["Cough"].asset_meta[asset].label}</span>)
      dat.push(<br />)
    })
    legendDat["symptoms"] = dat
    dat = []
    Object.keys(symptomMeta["Productive Cough"].asset_meta).forEach(asset => {
      dat.push(imageAsset(symptomMeta["Productive Cough"].asset_meta[asset].value))
      dat.push(<span className="legendText"> = </span>)
      dat.push(<span className="legendText">{symptomMeta["Productive Cough"].asset_meta[asset].label}</span>)
      dat.push(<br />)
    })
    legendDat["sputum"] = dat
    return (
      <div style={window.innerWidth > 1000 ? { marginTop: '72vw', fontSize: '0.9vw' } : { marginTop: '108vw', fontSize: '1.3vw' }}>
        <div className="legendStack">
          <div className="legendStackHead">Symptoms</div>
          {legendDat.symptoms}
        </div>
        <div className="legendStack">
          <div className="legendStackHead">Shortness of Breath</div>
          {legendDat.shortnessofbreath}
        </div>
        <div className="legendStack">
          <div className="legendStackHead">Sputum Color</div>
          {legendDat.sputum}
        </div>
      </div>);
  }
  //back pressed on report page
  const onBackClick = () => {
    history.push("/patients");
  }
  //print custom calendar 
  const getcalenderArea = () => {
    return (
      <div className="calendarArea">
        <PCalendar date={reportStartDate} data={userSessionData} selectedDate={handlePaginationCalender} handleMonthPagination={handlePagination} />
      </div>
    );
  };
  //handle patient information change
  const handlePatientInfoChange = () => {
    settNewPatientData({ ...newPatientData });
    setpatientInfoStatus(false);
    setshowEditPatient(true);
  };
  //handle show patient details expand
  const callback = (event) => {
    if (event.length > 0) {
      setTimeout(function () {
        setOpenPanels([]);
      }, 60000);
    }
    if (openPanels.length > 0) {
      setOpenPanels([]);
    } else {
      setOpenPanels(['1']);
    }
  }
  //Cancel add a new patient
  const closeAddUser = () => {
    setpatientInfoStatus(false);
    setshowEditPatient(false);
    form.resetFields();
    setNewPatientData({ ...tnewPatientData });
  };
  //Cancel patient report export
  const closePatientExport = () => {
    setshowPatientExport(false);
    formexport.resetFields();
  };

  const onExportClick = () => {
    setshowPatientExport(true);
    formexport.resetFields();
  };

  const handleNotesChange = (event) => {
    setexportNotes(event.target.value);
  }

  // for general error msg
  const errorMsg = (message) => {
    Modal.error({
      title: "Error!",
      content: message,
    });
  };
  //Modify patient
  const onSubmit = () => {
    setpatientInfoStatus(false);
    setshowEditPatient(false);
    let cognitodata = {};
    let formdata = {};
    Object.keys(newPatientData).map(function (key, index) {
      if (newPatientData[key] != tnewPatientData[key]) {
        if (key === "mrn") {
          cognitodata["custom:mrn"] = newPatientData[key];
        } else if (key === "fname") {
          cognitodata["given_name"] = newPatientData[key];
        } else if (key === "lname") {
          cognitodata["family_name"] = newPatientData[key];
        } else if (key === "email") {
          cognitodata["email"] = newPatientData[key];
        } else if (key === "phone") {
          cognitodata["phone_number"] = newPatientData[key];
        } else if (key === "age") {
          formdata["DOB"] = newPatientData[key];
        } else if (key === "sex") {
          formdata["Sex"] = newPatientData[key];
        } else if (key === "therapies") {
          formdata["Therapies"] = newPatientData[key];
        } else if (key === "medications") {
          formdata["Medications"] = newPatientData[key];
        } else if (key === "severity") {
          formdata["Severity"] = newPatientData[key];
        } else if (key === "height") {
          formdata["Height"] = newPatientData[key];
        } else if (key === "weight") {
          formdata["Weight"] = newPatientData[key];
        } else if (key === "lungcondition") {
          formdata["LungConditions"] = newPatientData[key];
        } else if (key === "serialno") {
          formdata["DeviceAssigned"] = newPatientData[key];
        } else if (key === "race") {
          formdata["Race"] = newPatientData[key];
        }
      }
    });
    Auth.currentSession()
      .then((user) => {
        const myInit = {
          headers: {
            Authorization: `Bearer ${user.idToken.jwtToken}`,
          },
          body: {
            patientid: location.state.key,
            "cognitodata": cognitodata,
            "formdata": formdata
          },
        };
        API.post(api.apiName, api.updatepatient, myInit)
          .then((response) => {
            if (response.type === "success") {
              Modal.success({
                title: "Success!",
                content: response.data,
                onOk() {
                  history.push("/patients");
                },
              });
            } else {
              errorMsg(response.data);
            }
          })
          .catch((error) => {
            console.log(error);
            errorMsg("Unknown Error Occured!")
          });
      }).catch(() => {
        setpageStatus(false);
        localStorage.clear();
        Auth.signOut();
        history.replace("/login");
      });
  };
  const handleChange = (event) => {
    let oldData = newPatientData;
    oldData[event.target.name] = event.target.value;
    setpatientInfoStatus(true);
    setNewPatientData(oldData);
  };
  const handleChangeSex = (event) => {
    let oldData = newPatientData;
    oldData["sex"] = event;
    setpatientInfoStatus(true);
    setNewPatientData(oldData);
  };
  const handleChangeRace = (event) => {
    let oldData = newPatientData;
    oldData["race"] = event;
    setpatientInfoStatus(true);
    setNewPatientData(oldData);
  };

  const onChangeDate = (date, val) => {
    let oldData = newPatientData;
    oldData["age"] = val;
    setpatientInfoStatus(true);
    setNewPatientData(oldData);
  };

  const { Panel } = Collapse;

  //handle patient report export
  const handlePatientReportExport = () => {
    if(exportNotes.length > 0){
      Auth.currentSession()
      .then((user) => {
        const myInit = {
          headers: {
            Authorization: `Bearer ${user.idToken.jwtToken}`,
          },
          body: {
            patientid: location.state.key,
            "exportData": {"notes":exportNotes,"providerId":user.idToken.payload.sub,"role":localStorage.getItem("currentOrgRole"),"organizationID":localStorage.getItem("currentOrgID"),"patientMRN":userMRN,"dateEndRange":reportStartDate}
          },
        };
       API.post(api.apiName, api.exportpatientNotes, myInit)
          .then((response) => {
            if (response.type === "success") {
              exportPatientReport();
            } else {
              exportPatientReport();
              errorMsg(response.data);
            }
          })
          .catch((error) => {
              exportPatientReport();
              console.log(error);
            errorMsg("Unknown Error Occured!")
          });
      }).catch(() => {
        localStorage.clear();
        Auth.signOut();
        history.replace("/login");
      });
    }else{
      exportPatientReport();
    }

  }

  //export report to PDF
  const exportPatientReport = () => {
    setshowPatientExport(false);
    handlePrint();
  }
  const pageStyle ="  @page {    size: letter portrait;  margin-left: 0px;    margin-right: 0px;    margin-top: 0px;    margin-bottom: 0px; }  @media print { body { -webkit-print-color-adjust: exact; } }";
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: userMRN,
    pageStyle:pageStyle
  });

  const cardWidthfactor = window.innerWidth > 1000 ? '90vw' : '97vw'
  const backButtonfactor = window.innerWidth > 1000 ? '1.1vw' : '1.7vw'
  const exportButtonfactor = window.innerWidth > 1000 ? 'large' : '1.7vw'
  //render page
  return (
    <div className="App">
      <Headers />
      <div className="TableContainer">
        <Card
          bordered={false}
          style={{ marginTop: 'max(1vw,5)', marginBottom: 'max(1vw,5)', width: cardWidthfactor }}
          title={
            <div className="table-header-text">
              <Button
                className="back-button"
                type="link"
                icon={<LeftOutlined />}
                size={backButtonfactor}
                onClick={onBackClick}
              >
                <span style={{ fontSize: backButtonfactor, fontWeight: "bold" }}>Back</span>
              </Button>
              {noUserData === "nodata" ?
                (<></>) : (
                  <>
                    <Button size={exportButtonfactor} ghost={true} className="export-button" onClick={onExportClick}> <span className="buttonTextExport">Export</span> </Button>
                    <div>
                    </div>
                  </>
                )
              }
            </div>
          }
        >
          {
            loadingPageStatus ? (<Loader
              type="TailSpin"
              color="rgb(68,192,192)"
              height={'11vw'}
              width={'11vw'}
            />) : (
              <>
                {
                  noUserData === "nodata" ?
                    (
                      <span style={{ fontSize: 16 }}>No user data found</span>
                    ) : (
                      <>
                        <div>
                          <Collapse activeKey={openPanels} onChange={callback}>

                            <Panel showArrow={false} header="Show Patient Details" key="1" className="site-collapse-custom-panel">
                              <div style={{ float: "left" }}>
                                <span style={{ color: "#282c34", fontSize: backButtonfactor }}>
                                  Patient Name: {userName} &nbsp;&nbsp;&nbsp;&nbsp; |
                                </span>
                                <span style={{ color: "#047181", fontSize: "1vw", marginLeft: "0.5vw" }}>Patient MRN : {userMRN}</span>
                              </div>
                              <br /><br />
                              <div className="patient-info">
                                <Row >
                                  {Object.keys(userData).map((key, index) => {
                                    return (
                                      <Col span={4} style={{ marginBottom: '1vw' }}>
                                        <Row className="row-header row-item1">
                                          <Col lg>
                                            <span style={{ fontSize: '0.9vw', fontWeight: 'bold' }}>{key}</span>
                                          </Col>
                                        </Row>
                                        <Row className={window.innerWidth > 1000 ? "row-item1" : "row-item"} >
                                          <Col>
                                            <label style={window.innerWidth > 1000 ? { fontSize: '0.9vw' } : { fontSize: '1.1vw' }}> {userData[key]} </label>
                                          </Col>
                                        </Row>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </div>
                              <div className="change-patient-info">
                                <Button
                                  type="link"
                                  size={"small"}
                                  onClick={handlePatientInfoChange}
                                >
                                  <span style={window.innerWidth > 1000 ? { fontSize: '0.9vw' } : { fontSize: '1.5vw' }}>Change Patient Information</span>
                                </Button>
                              </div>
                              <br />
                            </Panel>
                          </Collapse>
                        </div>
                        {noUserData === "nosessiondata" ? (
                          <span style={{ fontSize: 16 }}>No user session data found</span>
                        ) : (
                          <>
                            <div className="reportArea">
                              {getcalenderArea()}
                              <div className="chartArea">
                                <Card bordered={false} bodyStyle={{ paddingLeft: '1vw', paddingTop: '1vw' }}>
                                  <div >
                                    <Button
                                      className="chartPagination"
                                      type="text"
                                      onClick={() => handlePagination("left", 6, "day")}
                                      icon={<CaretLeftOutlined style={{ fontSize: 'max(1.8vw, 1.2em)' }} />}
                                    />
                                    {reportPagination({ fontSize: 'max(1.65vw, 1.3em)' })}
                                    <Button
                                      className="chartPagination"
                                      onClick={() => handlePagination("right", 6, "day")}
                                      type="text"
                                      icon={<CaretRightOutlined style={{ fontSize: 'max(1.8vw, 1.2em)' }} />}
                                    />
                                  </div>
                                  <div>
                                    <span style={{ fontSize: 'max(1vw, 0.85em)' }}>
                                      {sessionCount(7) + " sessions"}
                                    </span>
                                  </div>
                                  {lungFunctionChart()}
                                  {respiratoryRate()}

                                  {symptomMatrix()}
                                  {symptomMatrixLegend()}
                                </Card>
                              </div>
                            </div>
                          </>
                        )
                        }
                      </>
                    )
                }
              </>
            )
          }
        </Card>
        <Modal
          title="Export Patient Report"
          visible={showPatientExport}
          onOk={closePatientExport}
          onCancel={closePatientExport}
          className="patientExportModal"
          footer={[
            <Button size={"large"} className="export-button-submit" onClick={handlePatientReportExport}> <span>Export Patient Data</span> </Button>
          ]}
        >
          <div style={{ marginBottom: "0.3em" }}>
            <span style={{ color: "#282c34", fontSize: "1em", fontWeight: "bold" }}>
              Patient Name: {userName} &nbsp;&nbsp;&nbsp;&nbsp; |
            </span>
            <span style={{ color: "#047181", fontSize: "0.8em", marginLeft: "0.5em" }}>Patient MRN : {userMRN}</span>
          </div>
          <div style={{ fontSize: "1em", marginBottom: "1em" }}>Report Date Range: &nbsp; &nbsp; <span style={{ fontSize: "1.1em", fontWeight: "bold" }}>{reportPagination({ fontSize: 'max(1.2vw, 0.95em)' })}</span></div>

          <Form layout={"vertical"} form={formexport}>
            <Form.Item label="Notes: " className="formexport-input-notes" name="notes" style={{ marginRight: "3%", marginBottom: "2%" }} >
              <TextArea rows={5} placeholder="Any Additional Notes" autoComplete="off" onChange={handleNotesChange} name="notes" />
            </Form.Item>
          </Form>
          <div className="printComponent">

            <PrintV1 ref={componentRef}
              userName={userName}
              userMRN={userMRN}
              userData={userData}
              reportPagination={reportPagination()}
              sessionCnt={sessionCount(7)}
              sessionIds={sessionIds}
              userSessionData={userSessionData}
              lungFunctionColumn={lungFunctionColumn}
              buttonVisiblity={buttonVisiblity}
              sessionIdsForDates={sessionIdsForDates}
              respiratoryRateData={respiratoryRateData}
              temperatureData={temperatureData}
              symptommsData={symptommsData}
              symptomMeta={symptomMeta}
              symptommatrix={symptommatrix}
              notes={exportNotes}
              FEV1M={M}
              FEV1LLN={LLN}
              FVCM={fvcM}
              FVCLLN={fvcLLN}
            />
          </div>
        </Modal>

        {/* for Modal */}
        <Modal
          title="Edit Patient Information"
          visible={showEditPatient}
          onOk={closeAddUser}
          onCancel={closeAddUser}
          width={'70%'}
          style={{ top: 20, maxWidth: 720 }}
          footer={[
            <Button type="primary" key="submit" onClick={onSubmit} size={"large"} style={{ width: "30%", background: "rgb(68,192,192)", borderColor: "white" }} disabled={!patientInfoStatus}>
              Update/Save
            </Button>,
          ]}
        >
          <Form layout={"vertical"} form={form}>
            <div className="row">
              <Form.Item label="MRN*" className="form-input-mrn" name="mrn" style={{ marginRight: "3%", marginBottom: "2%" }} initialValue={newPatientData['mrn']}>
                <Input
                  placeholder="Enter Patient's MRN"
                  name="mrn"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
            </div>
            <div className="row">
              <Form.Item label="First Name*" className="form-input-fname" name="fname" style={{ marginRight: "3%", marginBottom: "2%" }} initialValue={newPatientData['fname']}>
                <Input
                  placeholder="Enter Patient's First Name"
                  name="fname"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item label="Last Name*" className="form-input-lname" name="lname" style={{ marginRight: "3%", marginBottom: "2%" }} initialValue={newPatientData['lname']}>
                <Input
                  placeholder="Enter Patient's Last Name"
                  name="lname"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
            </div>
            <div className="row">
              <Form.Item
                label="Email Address*"
                className="form-input-fname"
                name="email"
                style={{ marginRight: "3%", marginBottom: "2%" }}
                initialValue={newPatientData['email']}
              >
                <Input
                  placeholder="Enter Patient's Email Address"
                  name="email"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item label="Phone Number*" className="form-input-lname" name="phone" style={{ marginRight: "2%", marginBottom: "2%" }} initialValue={newPatientData['phone']}>
                <Input
                  placeholder="Enter Patient's Phone Number"
                  name="phone"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
            </div>
            <Divider style={{ margin: 3 }} />
            <div className="row">
              <Form.Item label="Date of Birth*" className="form-input" name="age" style={{ marginRight: "2%", marginBottom: "2%" }}  >
                <DatePicker name="age" onChange={onChangeDate} defaultValue={moment(newPatientData['age'], 'YYYY-MM-DD')} />
              </Form.Item>
              <Form.Item label="Sex at Birth*" className="form-input-sex" name="sex" style={{ marginRight: "2%", marginBottom: "2%" }} initialValue={newPatientData['sex']}>
                <Select
                  name="sex"
                  onChange={handleChangeSex}
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Race*" className="form-input-race" name="race" style={{ marginRight: "3%", marginBottom: "2%" }} initialValue={newPatientData['race']}>
                <Select
                  className="select-option-text-patientform"
                  name="race"
                  onChange={handleChangeRace}
                >
                  <Option value="Caucasian">Caucasian</Option>
                  <Option value="African American">African American</Option>
                  <Option value="North East Asian">North East Asian</Option>
                  <Option value="South East Asian">South East Asian</Option>
                  <Option value="Other/mixed">Other/mixed</Option>
                </Select>
              </Form.Item>
            </div>

            <div className="row">
              <Form.Item label="Height(in)*" className="form-input-fname" name="height" style={{ marginRight: "2%", marginBottom: "2%" }} initialValue={newPatientData['height']}>
                <Input
                  placeholder="Enter Patient's Height in inches."
                  name="height"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item label="Weight(lbs)*" className="form-input-lname" name="weight" style={{ marginBottom: "2%" }} initialValue={newPatientData['weight']}>
                <Input
                  placeholder="Enter Patient's Weight in pounds."
                  name="weight"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
            </div>

            <Divider style={{ margin: 3 }} />

            <div className="row">
              <Form.Item
                label="Device Serial Number"
                className="form-input"
                name="serialno"
                style={{ marginRight: "1%", marginBottom: "2%" }}
                initialValue={newPatientData['serialno']}
              >
                <Input
                  placeholder="Enter Vitalograph device serial number."
                  name="serialno"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                label="Type of Lung Condition"
                className="form-input-sex"
                name="lungcondition"
                style={{ marginRight: "1%", marginBottom: "2%" }}
                initialValue={newPatientData['lungcondition']}
              >
                <Input
                  placeholder="Select one of following"
                  name="lungcondition"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                label="Severity of Condition"
                className="form-input-sex"
                name="severity"
                style={{ marginRight: "3%", marginBottom: "2%" }}
                initialValue={newPatientData['severity']}
              >
                <Input
                  placeholder="Select one of following"
                  name="severity"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
            </div>

            <div className="row">
              <Form.Item
                label="Medications"
                className="form-input-fname"
                name="medications"
                style={{ marginRight: "3%" }}
                initialValue={newPatientData['medications']}
              >
                <Input
                  placeholder="Enter the medications list patient consume"
                  name="medications"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                label="Additional Airway Therapies"
                className="form-input-lname"
                name="therapies"
                initialValue={newPatientData['therapies']}
              >
                <Input
                  placeholder="Enter Patient's Airway therapies"
                  name="therapies"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default PatientDetail;
