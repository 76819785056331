import React, { useEffect, useState } from "react";
import "../App.css";
import { Auth, API } from "aws-amplify";
import { Button, Col, Row } from "antd";
import "antd/dist/antd.css";
import { api } from "../services/link";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { useHistory } from "react-router-dom";
import PatientsList from "./patients/patients-list";

const Dashboard = () => {
  let history = useHistory();

  const [patientsCount, setPatientsCount] = useState(0);
  const [providersCount, setProvidersCount] = useState(0);
  const [licenseCount, setlicenseCount] = useState('NA');
  const [sessionCount, setsessionCount] = useState(0);
  const [totalUsers, settotalUsers] = useState(0);
  const [orgUsers, setorgUsers] = useState(0);

  const [userId, setUserId] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [isProvider, setIsProvider] = useState(false);


  useEffect(() => {
    Auth.currentSession()
      .then((user) => {
        localStorage.setItem(
          "providerName",
          user.idToken.payload.given_name +
          " " +
          user.idToken.payload.family_name
        );
        setJwtToken(user.idToken.jwtToken);
        setUserId(user.accessToken.payload.username);
      })
      .catch(() => {
        history.push("/login");
      });

    const myInit = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: {
        username: userId,
        orgName: localStorage.getItem("currentOrgID")
          ? localStorage.getItem("currentOrgID")
          : "None",
      },
    };
    API.post(api.apiName, api.provider, myInit)
      .then((response) => {
        if (response.type === "success") {
          let role = response.message.role;
          localStorage.setItem("currentOrgRole", role);
          if (role === "provider") {
            setIsProvider(true);
          } else {
            setIsProvider(false);
          }
          if (response.message.organizations) {
            localStorage.setItem(
              "organizationList",
              JSON.stringify(response.message.organizations)
            );
            localStorage.setItem(
              "currentOrgID",
              response.message.organizations[0].orgID
            );
          }
          setPatientsCount(response.message.patients_count);
          setProvidersCount(response.message.providers_count);
          setlicenseCount(response.message.license_count);
          setsessionCount(response.message.session_count);
          settotalUsers(response.message.total_users);
          setorgUsers(response.message.org_admin_count)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [setJwtToken, setUserId, history, jwtToken, userId]);

  return (
    <>
      <div className="App">
        <Headers />
        <div className="TableContainer">
          {!isProvider ? (
            // for org admin
            <div style={{width:'100%', padding:'2em', maxWidth:'50em', minWidth:'10em'}}>
              <Row gutter={14} style={{  marginBottom: 14, height:'14em'  }}>
                <Col span={8} offset={0}>
                  <Button
                    className="set-click"
                    ghost
                    style={{
                      whiteSpace: "normal",
                      color: "white",
                    }}
                    type="primary"
                    onClick={() => {
                      history.push("/patients");
                    }}
                  >
                    <b>{patientsCount}</b> <br />
                    Patients
                  </Button>
                </Col>
                <Col span={8} offset={0} >
                  <Button
                    className="set-click"
                    ghost
                    style={{
                      whiteSpace: "normal",
                      color: "white",
                    }}
                    type="primary"
                    onClick={() => {
                      history.push("/providers");
                    }}
                  >
                    <b>{providersCount}</b> <br />
                    Providers
                  </Button>
                </Col>
                <Col span={8} offset={0}>
                  <Button
                    className="square-button"
                    ghost
                    style={{
                      whiteSpace: "normal",
                      color: "white",
                    }}
                    type="primary"
                  >
                    <b>{sessionCount}</b> <br />
                    Total Sessions
                  </Button>
                </Col>
              </Row>

              <Row gutter={14} style={{ marginBottom: 24, height:'14em'  }}>
                <Col span={8} offset={0} >
                  <Button
                    className="square-button"
                    ghost
                    style={{
                      whiteSpace: "normal",
                      color: "white",
                    }}
                    type="primary"
                  >
                    <b>{orgUsers}</b> <br />
                    Organization Admins
                  </Button>
                </Col>
                <Col span={8} offset={0} >
                  <Button
                    className="square-button"
                    ghost
                    style={{
                      whiteSpace: "normal",
                      color: "white",
                    }}
                    type="primary"
                  >
                    <b>{totalUsers}</b> <br />
                    Total Users
                  </Button>
                </Col>
                <Col span={8} offset={0} >
                  <Button
                    className="square-button"
                    ghost
                    style={{
                      whiteSpace: "normal",
                      color: "white",
                    }}
                    type="primary"
                    onClick={() => {

                    }}
                  >
                    <b>{licenseCount}</b> <br />
                    Devices
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <PatientsList />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
