/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_uOhWruNEk",
    "aws_user_pools_web_client_id": "76d6u0418fv0ggs2746ulm8bbe",
    "oauth": {},
    API: {
        endpoints: [
            {
                name: "APIGateway",
                endpoint: "https://e7qgejc0xg.execute-api.us-east-2.amazonaws.com/dev"
            }
        ]
    }
};

export default awsmobile;
