import React from "react";
import { Auth } from "aws-amplify";
import { Divider, Select } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import headerLogo from "../assets/images/logo2.png";

import "antd/dist/antd.css";
import "../App.css";
import "semantic-ui-css/semantic.min.css";

const { Option } = Select;
const widthfactor = window.innerWidth >1000 ? '25vh':'15vh'
const fontwidthfactor = window.innerWidth >1000 ? '0.9vw':'1.5vw'
const orgfontwidthfactor = window.innerWidth >1000 ? '1.05vw':'1.8vw'
const menufontwidthfactor = window.innerWidth >1000 ? '1.05vw':'1.68vw'

const Headers = () => {
  let history = useHistory();

  const orgAdminMenu = (
    <div className="left menu">
      <Link className="item link-text" to="/">
        Home
      </Link>
      <Link className="item link-text" to="/patients">
        Patients
      </Link>
      <Link className="item link-text" to="/providers">
        Providers
      </Link>
      <Link className="item link-text-disabled">Accounts</Link>
    </div>
  );
  const orgProviderMenu = (
    <div className="left menu">
      <Link className="item link-text" to="/">
        Home
      </Link>
      <Link className="item link-text" to="/patients">
        Patients
      </Link>
      <Link className="item link-text-disabled">Accounts</Link>
    </div>
  );
  const organizationList = localStorage.getItem("organizationList")
    ? JSON.parse(localStorage.getItem("organizationList"))
    : [];
  const orgOptions = organizationList.map((d) => (
    <Option className="select-option-text-options" key={d.orgID}>
      <b>{d.name}</b>
    </Option>
  ));

  const signOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    Auth.signOut();
    history.replace("/login");
  };
  const handleOrgChange = (event) => {
    for (var option = 0; option < organizationList.length; option++) {
      if (organizationList[option].orgID === event) {
        var tOpt = organizationList[option];
        organizationList.splice(option, 1);
        organizationList.splice(0, 0, tOpt);
        break;
      }
    }
    localStorage.setItem("organizationList", JSON.stringify(organizationList));
    localStorage.setItem("currentOrgID", event);
    if (history.location.pathname === "/") {
      window.location.reload();
    } else history.replace("/");
  };

  return (
    <>
      <nav className="navbar-top">
        <div className="ui pointing secondary menu" style={{ border: 0 }}>
          <div className="top-menu-left">
            <img style={{minWidth:widthfactor}} src={headerLogo} className="headerLogo" />
          </div>
          <div className="top-menu-right">
            <div className="right menu">
              {organizationList.length > 0 && (
                <Select
                  className="select-option-text"
                  defaultValue={organizationList[0].name}
                  onChange={handleOrgChange}
                  bordered={false}
                  style={{fontSize:orgfontwidthfactor}}
                >
                  {orgOptions}
                </Select>
              )}

              <Divider className="vertical-divider" type="vertical" />
              <h3 style={{ margin: 0,fontSize:fontwidthfactor }} className="navUser">
                <b>Provider: </b>{" "}
                {localStorage.getItem("providerName")
                  ? localStorage.getItem("providerName")
                  : ""}{" "}
              </h3>
            </div>
          </div>
        </div>
      </nav>

      <nav className="navbar-bottom" >
        <div className="ui pointing secondary menu" style={{fontSize:menufontwidthfactor, paddingLeft:'5vw'}}>
          <div className="nav-menu-left" >
            {localStorage.getItem("currentOrgRole") === "Org_Admin"
              ? orgAdminMenu
              : orgProviderMenu}
          </div>
          <div className="nav-menu-right">
            <div className="right menu" style={{fontSize:menufontwidthfactor}}>
              <Link className="item link-text" onClick={signOut}>
                Logout
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Headers;
